import StarEmpty from '@petplate/icons/core/StarEmpty';
import StarFull from '@petplate/icons/core/StarFull';
import lens from '@refract-ui/sc/lens';
import React from 'react';
import { css } from 'styled-components';

interface Props {
  score?: number;
}

const StarWrapper = lens.div()`
  display: flex;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const CurrentStars = lens.div()<{ $width: number }>`
  ${({ $width }) => css`
    overflow: hidden;
    height: 24px;
    width: ${$width}%;
    white-space: nowrap;
    z-index: 1;
  `}
`;

const EmptyStars = lens.div()`
  position: absolute;
`;

const MAX_STARS = 5;

export const Stars: React.FC<Props> = ({ score = 0 }) => {
  return (
    <StarWrapper>
      <CurrentStars $width={(score * 100) / 5}>
        {[...Array(MAX_STARS)].map((_s, idx) => (
          <StarFull key={idx} color="#0068E1" />
        ))}
      </CurrentStars>
      <EmptyStars>
        {[...Array(MAX_STARS)].map((_s, idx) => (
          <StarEmpty key={idx} color="#E0E3E0" />
        ))}
      </EmptyStars>
    </StarWrapper>
  );
};

export default Stars;
