import { cookiesManager } from '@/lib/utils/cookiesManager';
import {
  SHOPIFY_API_VERSION,
  SHOPIFY_CUSTOM_DOMAIN,
  SHOPIFY_STOREFRONT_ACCESS_TOKEN
} from '@petplate/settings';
import DiscountProvider from '@petplate/ui/components/DiscountProvider';
import { FB_ID_COOKIE } from '@petplate/ui/lib/cookies';
import { CartProvider, ShopifyProvider } from '@shopify/hydrogen-react';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useSiteWideDiscounts } from '../../lib/siteWideDiscounts';
import { AnalyticsProvider } from './AnalyticsProvider';
import { CurrentUserProvider } from './CurrentUserProvider';

export const Providers = () => {
  const [fbIdentifier, setFbIdentifier] = useState<string>();
  const {
    percentCoupon,
    amountCoupon,
    siteWideBackupCode,
    subCancelledEntreesDiscount,
    isLoading
  } = useSiteWideDiscounts();

  useEffect(() => {
    const savedId = cookiesManager.get(FB_ID_COOKIE);
    if (!fbIdentifier && !savedId) {
      const newId = uuid();
      setFbIdentifier(newId);
      cookiesManager.set(FB_ID_COOKIE, newId, { expires: 30 });
    }
  }, []);

  return (
    <AnalyticsProvider>
      <ShopifyProvider
        storeDomain={`https://${SHOPIFY_CUSTOM_DOMAIN}`}
        storefrontToken={SHOPIFY_STOREFRONT_ACCESS_TOKEN}
        storefrontApiVersion={SHOPIFY_API_VERSION || '2024-10'}
        countryIsoCode="US"
        languageIsoCode="EN"
      >
        <CartProvider>
          <DiscountProvider
            loading={isLoading}
            discountPercentCoupon={percentCoupon}
            siteWideBackupCode={siteWideBackupCode}
            discountAmountCoupon={amountCoupon}
            subCancelledEntreesDiscount={subCancelledEntreesDiscount}
          >
            <CurrentUserProvider>
              <Outlet />
            </CurrentUserProvider>
          </DiscountProvider>
        </CartProvider>
      </ShopifyProvider>
    </AnalyticsProvider>
  );
};
