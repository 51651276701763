import googleAnalytics from '@analytics/google-analytics';
import googleTagManager from '@analytics/google-tag-manager';
import { type GetShopifyOrderQuery } from '@petplate/schema';
import { GOOGLE_ANALYTICS_MEASUREMENT_ID, GTM_CONTAINER_ID } from '@petplate/settings';
import Analytics from 'analytics';
import last from 'lodash/last';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const analytics = Analytics({
  app: 'petplate',
  debug: true,
  plugins: [
    googleTagManager({
      containerId: GTM_CONTAINER_ID
    }),
    googleAnalytics({
      measurementIds: [GOOGLE_ANALYTICS_MEASUREMENT_ID]
    })
  ]
});

export const trackPurchase = (order: GetShopifyOrderQuery | undefined) => {
  if (order) {
    const orderDetail = order?.orderDetail;
    const payload = {
      ecommerce: {
        transaction_id: last(orderDetail?.id.split('/')),
        value: orderDetail?.currentSubtotalPriceSet?.presentmentMoney?.amount,
        currency: 'USD',
        items: orderDetail?.lineItems?.edges?.map(({ node }) => {
          return {
            item_id: node?.id,
            item_name: node?.name,
            price: node?.unfulfilledOriginalTotalSet?.shopMoney?.amount,
            quantity: node?.quantity,
            discount: node?.totalDiscountSet?.shopMoney?.amount
          };
        })
      }
    };

    analytics.track('purchase', payload);
  }
};

export const useTrackPageChange = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    analytics.page({
      event: 'pageview'
    });
  }, [pathname]);
};

export const AnalyticsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  useTrackPageChange();
  return <>{children}</>;
};
