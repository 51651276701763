import { useAsyncError } from '@/lib/hooks/useAsyncError';
import { useCurrentFlow } from '@/lib/hooks/useCurrentFlow';
import { useCurrentUser } from '@/lib/hooks/useCurrentUser';
import { useLatestStep } from '@/lib/hooks/useLatestStep';
import { RecWizardContext, type RecWizardContextProps } from '@/lib/hooks/useRecWizard';
import { fetchSessionWizard, updateSessionWizard } from '@/lib/utils/recWizard';
import { type RecWizard } from '@/types/recWizard';
import { type Step } from '@/types/steps';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const RecWizardProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { pathname, search } = useLocation();
  const { currentUser, loading: loadingUser } = useCurrentUser();
  const navigate = useNavigate();
  const [wizard, setWizard] = useState<Partial<RecWizard>>();
  const [loading, setLoading] = useState(true);
  const throwAsyncError = useAsyncError();
  const latestStep = useLatestStep(wizard);
  const { flow } = useCurrentFlow();

  const updateWizard: RecWizardContextProps['updateWizard'] = useCallback(
    (values) => setWizard((prevWizard) => ({ ...prevWizard, ...values, latestStep })),
    [latestStep]
  );

  const startWizard = () => {
    fetchSessionWizard({ flow, step: pathname as Step['fullPath'], currentUser })
      .then((wizardOrRedirect) => {
        if (typeof wizardOrRedirect === 'string') {
          // Reset loading flag to reload the cookie data after redirect
          setLoading(true);
          // Keep the query params when redirecting
          navigate({ pathname: wizardOrRedirect, search });
        } else {
          setLoading(false);
          updateWizard(wizardOrRedirect);
        }
      })
      .catch(throwAsyncError);
  };

  useEffect(() => {
    if (wizard) updateSessionWizard(wizard);
  }, [wizard]);

  // Fetch wizard from cookie or init a new one
  useEffect(() => {
    if (!wizard && !loadingUser) startWizard();
  }, [pathname, loadingUser]);

  return (
    <RecWizardContext.Provider value={{ wizard, updateWizard, loading }}>
      {children}
    </RecWizardContext.Provider>
  );
};
