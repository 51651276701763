import { type Coupon } from '@petplate/utils/coupons';
import { captureException } from '@sentry/react';
import find from 'lodash/find';
import { useEffect, useState } from 'react';
import useURLCouponCode, { INVALID_URL_COUPON } from './hooks/useURLCouponCode';
import { sdk } from './utils/sdk';

export type GetCouponsQuery = {
  settings: Coupon[];
};

export const useSiteWideDiscounts = () => {
  try {
    const [resp, setResp] = useState<GetCouponsQuery | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);

    const fetchSiteWideDiscounts = async () => {
      setIsLoading(true);
      try {
        const settingsData = (await sdk.GetSettings({
          vars: ['site_wide_coupon', 'site_wide_backup_code', 'sub_cancelled_entrees_discount'] //, 'site_wide_addons_coupon' Ignore free addons coupon for now
        })) as GetCouponsQuery;
        setResp(settingsData);
      } catch (e) {
        captureException(e);
      } finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      fetchSiteWideDiscounts();
    }, []);

    const [externalCouponPercentOff, isLoadingExternalCouponData, isExternalCoupon, couponURL] =
      useURLCouponCode();

    const urlCouponDataInvalid = externalCouponPercentOff === INVALID_URL_COUPON;

    let percentCoupon = find(resp?.settings, (s) => !!s.percentOff);
    if (isExternalCoupon && !urlCouponDataInvalid) {
      percentCoupon = {
        code: couponURL || '',
        percentOff: externalCouponPercentOff,
        amountOff: 0,
        maskedCode: '',
        isURLCoupon: true
      };
    }

    const amountCoupon = find(resp?.settings, (s) => !!s.amountOff);
    const siteWideBackupCode = find(resp?.settings, (s) => s.code.includes('INTRO2'));
    const subCancelledEntreesDiscount = find(resp?.settings, (s) => s.code.includes('ENTREES'));

    return {
      percentCoupon,
      amountCoupon,
      siteWideBackupCode,
      subCancelledEntreesDiscount,
      isLoading: isLoading || isLoadingExternalCouponData
    };
  } catch (err) {
    console.error(err);
    captureException(err);

    return {
      percentCoupon: {},
      amountCoupon: {},
      siteWideBackupCode: {},
      subCancelledEntreesDiscount: {},
      isLoading: false
    };
  }
};
