import { useCallback, useState } from 'react';

/**
 * Hook that can be used to catch errors from async code (i.e. promises)
 * and throws a rendering error that can be caught by Error Boundaries
 *
 * @throws a rendering error
 */
export const useAsyncError = () => {
  const [_, setError] = useState();
  return useCallback(
    (e: Response | Error) => {
      setError(() => {
        throw e;
      });
    },
    [setError]
  );
};
