import { cookiesManager } from '@/lib/utils/cookiesManager';
import { importExistingWizard } from '@/lib/utils/recWizard';
import { deserializeCreds, persistCreds } from '@/lib/utils/sdk';
import { Flows, SubflowSteps } from '@/types/steps';
import { GUEST_TOKEN_COOKIE } from '@petplate/ui/lib/cookies';
import { captureException } from '@sentry/react';
import { redirect } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RouterLoader = ({ request }: { request: Request }) => Promise<any>;

export const handleResumeSubFlow: RouterLoader = async ({ request }) => {
  const url = new URL(request.url);
  const wizardId = url.searchParams.get('wizardId');
  const guestToken = url.searchParams.get('guestToken');
  const authorization = url.searchParams.get('authorization');

  if (!wizardId || !guestToken) {
    captureException(`Unable to resume recommendation wizard with id=${wizardId}`);
    throw new Response('Unable to set up wizard', { status: 404 });
  }

  // If there is an auth token,
  // we store the credentials to "sign in" the user
  if (authorization) {
    const credentials = deserializeCreds(decodeURIComponent(authorization));
    persistCreds(credentials);
  }

  // Persist guestToken, required for fetching wizard from API
  cookiesManager.set(GUEST_TOKEN_COOKIE, guestToken);

  const wizard = await importExistingWizard(wizardId);
  if (!wizard) {
    captureException(`Cannot find recommendation wizard with id=${wizardId}`);
    throw new Response('Recommendation wizard not found', { status: 404 });
  }

  // Remove unwanted search params before redirecting
  url.searchParams.delete('wizardId');
  url.searchParams.delete('guestToken');
  url.searchParams.delete('authorization');

  // Try to redirect to the plan confirmation step
  // The wizard will redirect again to the latest step available depending on wizard values
  // NOTE: Splitting by '/' should always return something with this format: ['', 'subscription', 'intro']
  const wizardFlow = wizard.latestStep?.split('/')[1] || Flows.Regular;
  return redirect(`/${wizardFlow}${SubflowSteps.PlanConfirmation}?${url.searchParams.toString()}`);
};
