import { SVGProps } from 'react';
const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '20'}
    height={props.height || '20'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={props.viewBox || '0 0 20 20'}
    {...props}
  >
    <g clipPath="url(#close_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.402 4.341a.75.75 0 1 0-1.06 1.061L8.938 10l-4.597 4.598a.75.75 0 0 0 1.06 1.06L10 11.062l4.598 4.598a.75.75 0 0 0 1.06-1.061L11.062 10l4.598-4.598a.75.75 0 1 0-1.061-1.06L10 8.939 5.402 4.341Z"
        fill={props.color || '#131923'}
        stroke={props.color || '#131923'}
        strokeWidth={0.1}
        vectorEffect={`non-scaling-stroke`}
      />
    </g>
    <defs>
      <clipPath id="close_svg__a">
        <path
          fill="#fff"
          d="M0 0h20v20H0z"
          stroke={props.color || '#131923'}
          strokeWidth={0.1}
          vectorEffect={`non-scaling-stroke`}
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgClose;
