import { SVGProps } from 'react';
const SvgCircleCheckmark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '20'}
    height={props.height || '20'}
    fill={props.fill || 'none'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={props.viewBox || '0 0 20 20'}
    {...props}
  >
    <circle
      cx={10}
      cy={10}
      r={8}
      stroke={props.stroke || '#131923'}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="m6.75 10.083 1.765 1.667 4.235-4"
      stroke={props.color || '#131923'}
      strokeWidth={2}
      strokeLinecap="round"
      vectorEffect={`non-scaling-stroke`}
    />
  </svg>
);
export default SvgCircleCheckmark;
