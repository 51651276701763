import { SVGProps } from 'react';
const SvgStarEmpty = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '20'}
    height={props.height || '20'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={props.viewBox || '0 0 20 20'}
    {...props}
  >
    <g clipPath="url(#star-empty_svg__a)">
      <path
        d="M11.046 15.85a1.95 1.95 0 0 0-2.092 0L5.86 17.816a.45.45 0 0 1-.681-.477l.864-3.898a1.95 1.95 0 0 0-.59-1.863l-2.93-2.672a.45.45 0 0 1 .263-.78l3.74-.335A1.95 1.95 0 0 0 8.16 6.577l1.423-3.532a.45.45 0 0 1 .834 0l1.423 3.532a1.95 1.95 0 0 0 1.635 1.214l3.74.334a.45.45 0 0 1 .263.781l-2.93 2.672a1.95 1.95 0 0 0-.59 1.863l.864 3.898a.45.45 0 0 1-.68.477l-3.096-1.966Z"
        stroke={props.color || '#131923'}
        strokeWidth={2}
        vectorEffect={`non-scaling-stroke`}
      />
    </g>
    <defs>
      <clipPath id="star-empty_svg__a">
        <path
          fill="#fff"
          d="M0 0h20v20H0z"
          stroke={props.color || '#131923'}
          strokeWidth={2}
          vectorEffect={`non-scaling-stroke`}
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgStarEmpty;
