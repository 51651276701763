import { headerHeightLg, headerHeightXs } from '@petplate/ui/constants';
import { createGlobalStyle } from 'styled-components';

// Verical offset to account for the fixed header
// when scrolling on the page.
const ScrollToOffset = createGlobalStyle`
  html {
    scroll-padding-top: ${headerHeightXs}px;
    ${({ theme: { mq } }) => mq.lg`
      scroll-padding-top: ${headerHeightLg}px;
    `}
  }
`;

export default ScrollToOffset;
