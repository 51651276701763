import { usePageTitle } from '@/lib/hooks/usePageTitle';
import { Outlet } from 'react-router-dom';
import { Container, HeadContainer, HeadWrapper, StyledLogo, TopConfirmation } from '../components';

export const OrderConfirmationLayout = () => {
  usePageTitle('Order Confirmation');

  return (
    <Container>
      {/* Banner placeholder necessary or layout breaks */}
      <div />
      <HeadWrapper>
        <HeadContainer>
          <TopConfirmation>
            <StyledLogo />
          </TopConfirmation>
        </HeadContainer>
      </HeadWrapper>
      <Outlet />
    </Container>
  );
};
