import { DOMAIN_PREFIX, ENV } from '@petplate/settings';
import Cookies from 'js-cookie';

const PREFIX = DOMAIN_PREFIX && `.${DOMAIN_PREFIX}`;
const PETPLATE_DOMAIN = PREFIX ? `${PREFIX}.petplate.com` : '.petplate.com';

export const cookiesManager = Cookies.withAttributes({
  domain: ENV === 'production' ? PETPLATE_DOMAIN : undefined,
  path: '/'
});
