import { useCaptcha } from '@/lib/hooks/useCaptcha';
import { CurrentUserContext } from '@/lib/hooks/useCurrentUser';
import { retrieveCreds, sdk } from '@/lib/utils/sdk';
import { type CurrentUser } from '@/types/currentUser';
import { captureException } from '@sentry/react';
import React, { useCallback, useEffect, useState } from 'react';

export const CurrentUserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<CurrentUser>();
  const [loading, setLoading] = useState(true);
  const { verifyCaptcha, captchaReady } = useCaptcha();

  const loadUser = useCallback(async () => {
    try {
      setLoading(true);
      if (retrieveCreds()) {
        const resp = await sdk.GetUser();
        setCurrentUser(resp?.me);
      }
    } catch (err) {
      setCurrentUser(undefined);
    } finally {
      setLoading(false);
    }
  }, []);

  const signOut = useCallback(async () => {
    try {
      await sdk.UserSignOut();
      setCurrentUser(undefined);
      return true;
    } catch (err) {
      return false;
    }
  }, []);

  const signIn = useCallback(
    async (email: string, password: string) => {
      try {
        if (captchaReady) {
          const captchaSuccess = await verifyCaptcha('signIn');
          if (captchaSuccess) {
            setLoading(true);
            const { userLogin } = await sdk.SubflowSignIn({ email, password });
            const user = userLogin?.authenticatable;
            if (user) setCurrentUser(user);
            return user;
          }
        }
        throw Error('reCaptcha test failed');
      } catch (err) {
        console.error(`Failed to authenticate user: ${err}`);
        captureException(err);
        return undefined;
      } finally {
        setLoading(false);
      }
    },
    [verifyCaptcha, captchaReady]
  );

  useEffect(() => {
    if (!currentUser) loadUser();
  }, []);

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        loading,
        signOut,
        signIn
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};
