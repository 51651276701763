import { NEXT_PUBLIC_YOTPO_APP_KEY } from '@petplate/settings';

export const REVIEW_TYPE_PRODUCT = 'product';
export const REVIEW_TYPE_TREATS = 'treats';
export const REVIEW_TYPE_COMPANY = 'company';
export const REVIEW_TYPE_SUPPLEMENTS = 'supplements';

export type ReviewCategoryType =
  | typeof REVIEW_TYPE_PRODUCT
  | typeof REVIEW_TYPE_TREATS
  | typeof REVIEW_TYPE_COMPANY
  | typeof REVIEW_TYPE_SUPPLEMENTS;

type PaginationType = {
  perPage: number;
  page: number;
};

/**
 * Returns the average score of the reviews for a product
 *
 * @param productSku the product to fetch the score
 * @returns the score of the product
 */
export const fetchProductAvgScore = async (productSku: string) => {
  const baseUrl = `https://api-cdn.yotpo.com/products/${NEXT_PUBLIC_YOTPO_APP_KEY}/${productSku}/bottomline`;
  try {
    const resp = await fetch(baseUrl);
    if (resp.ok && resp.status === 200) {
      const data = await resp.json();
      return data?.response?.bottomline?.average_score as number;
    }
  } catch {
    // product most likely not found
  }
  return 0;
};

/**
 *
 * @param object Reviewed object
 * @param pagination pagination params
 * @returns a string with the link to fetch reviews from yotpo
 */
export function buildReviewUrl(object: string, pagination?: PaginationType): string {
  const baseUrl = `https://api.yotpo.com/v1/widget/${NEXT_PUBLIC_YOTPO_APP_KEY}/products/${object}/reviews.json`;
  if (pagination) {
    return `${baseUrl}?per_page=${pagination.perPage}&page=${pagination.page}`;
  } else {
    return baseUrl;
  }
}

export type YotpoReviewType = {
  content?: string;
  score?: number;
  id?: number;
  product_id?: number;
  user?: {
    display_name?: string;
  };
};

export type YotpoProduct = {
  id?: number;
  domain_key?: string;
  name?: string;
};

export type ReviewType = {
  score?: number | undefined;
  name?: string | undefined;
  content?: string | undefined;
  id?: number;
  productId?: number;
};

export type ProductType = {
  id?: number;
  sku?: string;
  name?: string;
};

/**
 *
 * @param reviews the reviews from the yotpo api
 * @returns a normalized version of an yotpo review
 */
export function mapYotpoReviews(reviews: YotpoReviewType[]): ReviewType[] {
  return reviews?.map((review) => ({
    content: review?.content,
    name: review?.user?.display_name,
    score: review?.score,
    id: review?.id,
    productId: review?.product_id
  }));
}

export function mapYotpoProducts(products: YotpoProduct[]): ProductType[] {
  return products?.map((product) => ({
    id: product?.id,
    sku: product?.domain_key,
    name: product?.name
  }));
}
