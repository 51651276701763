import store from 'storejs';

const LOCAL_STORAGE_PREFIX = 'PetPlate-';

export const LocalStorageService = {
  get: (key: string) => store.get(`${LOCAL_STORAGE_PREFIX}${key}`),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set: (key: string, value: any) => {
    store.set(`${LOCAL_STORAGE_PREFIX}${key}`, value);
  },
  delete: (key: string) => {
    store.remove(`${LOCAL_STORAGE_PREFIX}${key}`);
  },
  hasKey: (key: string) => store.get(`?${LOCAL_STORAGE_PREFIX}${key}`)
};

/**
 * CURRENTLY USED KEYS
 *
 * Add new keys as needed here
 */
export const VIEWED_BANNER_IDS_KEY = 'ViewedBannerIds';
export const TREATS_PROMO_VIEWED_KEY = 'TreatsPromoViewed';
export const REC_WIZARD_KEY = 'RecWizardDefinition';
