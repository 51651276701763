import { maxContentWidth } from '@petplate/ui/constants';
import { css } from 'styled-components';
import { DefaultTheme } from 'styled-components/dist/types';
import { genResponsiveStyles, ResponsiveStyles } from '../utils/genResponsiveStyles';

// values are in rem
export const extraTextPadding = {
  xxs: 0.625,
  sm: 0.625,
  md: 0.625,
  lg: 2.5,
  xl: 2.5
};

export const defaultGutter = {
  xxs: 1.5,
  sm: 1.75,
  md: 1.75,
  lg: 1.75,
  xl: 2.5
};

export const gridMargins = {
  xxs: 1.5,
  sm: 1.75,
  md: 1.75,
  lg: 1.75,
  xl: 2.5
};

export const containerCss = (responsiveStyles?: ResponsiveStyles) => {
  return css`
    display: grid;
    justify-items: center;
    max-width: 100vw;
    overflow: hidden;
    width: 100%;
    ${({ theme }) =>
      responsiveStyles &&
      css`
        ${genResponsiveStyles(responsiveStyles, theme)}
      `}
  `;
};

// Inner Block Container w/ default design system grid
// https://design.petplate.com/4c865b02e/p/405e80-layout/b/56984a
export const contentCss = (
  { maxWidth = maxContentWidth, theme }: { maxWidth?: string; theme?: DefaultTheme } = {
    maxWidth: maxContentWidth
  }
) => {
  return css`
    display: grid;
    gap: ${defaultGutter?.xxs}rem;
    grid-template-columns: repeat(4, 1fr);
    max-width: ${maxWidth};
    overflow: hidden;
    width: 100%;

    ${({ theme: { mq } }) => mq.sm`
      gap: ${defaultGutter?.sm}rem;
      grid-template-columns: repeat(6, 1fr);
    `}
    ${({ theme: { mq } }) => mq.lg`
      grid-template-columns: repeat(12, 1fr);
    `}
    ${({ theme: { mq } }) => mq.xl`
      gap: ${defaultGutter?.xl}rem;
    `}
  `;
};

// Grid & gap values that line up w/ design system grid
// https://design.petplate.com/4c865b02e/p/405e80-layout/b/56984a
export const gridSystem = () => {
  return css`
    display: grid;
    gap: ${defaultGutter?.xxs}rem;
    grid-template-columns: repeat(4, 1fr);

    ${({ theme: { mq } }) => mq.sm`
      gap: ${defaultGutter?.sm}rem;
      grid-template-columns: repeat(6, 1fr);
    `}
    ${({ theme: { mq } }) => mq.lg`
      grid-template-columns: repeat(12, 1fr);
    `}
    ${({ theme: { mq } }) => mq.xl`
      gap: ${defaultGutter?.xl}rem;
    `}
  `;
};

// Only gap values that line up w/ design system grid
// https://design.petplate.com/4c865b02e/p/405e80-layout/b/56984a
export const gridSystemGutters = () => {
  return css`
    gap: ${defaultGutter?.xxs}rem;
    ${({ theme: { mq } }) => mq.sm`
      gap: ${defaultGutter?.sm}rem;
    `}
    ${({ theme: { mq } }) => mq.xl`
      gap: ${defaultGutter?.xl}rem;
    `}
  `;
};

// Only outer margin values that line w/ design grid
// https://design.petplate.com/4c865b02e/p/405e80-layout/b/56984a
export const gridSystemMargins = () => {
  return css`
    ${({ theme: { mq } }) => css`
      padding-left: ${gridMargins?.xxs}rem;
      padding-right: ${gridMargins?.xxs}rem;

      ${mq.sm`
        padding-left: ${gridMargins?.sm}rem;
        padding-right: ${gridMargins?.sm}rem;
      `}
      ${mq.xl`
        padding-left: ${gridMargins?.xl}rem;
        padding-right: ${gridMargins?.xl}rem;
      `}
    `}
  `;
};

// When Theme is Updated, remove this or at least
// Make it return an empty CSS string. For now,
// this will return the line-height values corresponding to,
// but converted as line-height / font-size = value:
// https://design.petplate.com/4c865b02e/p/4520d4-typography/b/779dec
export const typographyPatch = (tagName = 'bodyMd') => {
  return css``;
};
