import isEmpty from 'lodash/isEmpty';
import { DefaultTheme } from 'styled-components';

export type StyleTypes = {
  breakpoint: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  marginTop?: string | number;
  marginBottom?: string | number;
  id?: string;
};

export type CssPropTypes = {
  value: string | number;
  spacing: { [x: string]: string | number };
};

export type ResponsiveStyles = StyleTypes[];

const cssProps = {
  marginTop: ({ value, spacing }: CssPropTypes) => `margin-top: ${spacing[value]}rem;`,
  marginBottom: ({ value, spacing }: CssPropTypes) => `margin-bottom: ${spacing[value]}rem;`
};

// converts responsive style field values into correct css to return to component
export const genResponsiveStyles = (cmsFields: ResponsiveStyles, { mq, spacing }: DefaultTheme) => {
  if (isEmpty(cmsFields)) return null;

  return Object.entries(mq).map(([bp]) => {
    const cmsFieldsAtBp = cmsFields.find((breakpointStyles) => breakpointStyles?.breakpoint === bp);

    if (!cmsFieldsAtBp || isEmpty(cmsFieldsAtBp)) return null;

    const bpStyles = Object.entries(cmsFieldsAtBp).map(([prop, value]) => {
      if (!value || !cssProps[prop as keyof typeof cssProps]) return null;
      return cssProps[prop as keyof typeof cssProps]({ value, spacing: { ...spacing, 2: 0.625 } });
    });

    return mq[bp as keyof typeof mq]`${bpStyles.join('\n')}`;
  });
};
