import { DOMAIN_PREFIX, ENV } from '@petplate/settings';
import { NextRequest } from 'next/server';
import { destroyCookie, setCookie } from 'nookies';

const PREFIX = DOMAIN_PREFIX && `.${DOMAIN_PREFIX}`;
const PETPLATE_DOMAIN = PREFIX ? `${PREFIX}.petplate.com` : '.petplate.com';

// Expects the cart cookie to have a string format of the type:
// gid://shopify/Cart/[a-zA-Z0-9\-]{35}?key=[a-fA-F0-9]{32}
export const parseShopifyCartId = (shopifyCartIdCookie: string) => {
  const regex = /gid:\/\/shopify\/Cart\/(.+)\?.*/;
  // test is required here because replace function returns
  // the full string back if no group matches were found
  if (regex.test(shopifyCartIdCookie)) {
    return shopifyCartIdCookie.replace(regex, '$1');
  }
  return '';
};

// the method for retrieving cookie values will differ depending on whether
// this is a client or server side request. this method is isomorphic, meaning
// it will return the cookie regardless of the environment
/* eslint-disable @typescript-eslint/no-var-requires */
export const readCookie = (cookieName: string, req?: NextRequest) => {
  let cookieVal: string;

  if (req) {
    cookieVal = req.cookies.get(cookieName)?.value || '';
  } else if (typeof window === 'undefined') {
    const cookies = require('next/headers').cookies;
    const cookieStore = cookies();
    cookieVal = cookieStore.get(cookieName)?.value;
  } else {
    const cookies = require('nookies');
    const cookieStore = cookies.parseCookies();
    cookieVal = cookieStore?.[cookieName];
  }

  return cookieVal;
};
/* eslint-enable @typescript-eslint/no-var-requires */

export const setGlobalCookie = (key: string, value: string, opts?: object) => {
  setCookie(null, key, value, {
    domain: ENV === 'production' ? PETPLATE_DOMAIN : undefined,
    path: '/',
    ...(opts || {})
  });
};

export const removeGlobalCookie = (key: string, opts?: object) => {
  destroyCookie(null, key, {
    domain: ENV === 'production' ? PETPLATE_DOMAIN : undefined,
    path: '/',
    ...(opts || {})
  });
};
