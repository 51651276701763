import { trackPurchase } from '@/components/providers/AnalyticsProvider/AnalyticsProvider';
import { RecWizard } from '@/types/recWizard';
import { parseShopifyCartId } from '@petplate/sdk/cookies';
import { SUBFLOW_URL, WEB_URL } from '@petplate/settings';
import { SHOPIFY_CART_COOKIE } from '@petplate/ui/lib/cookies';
import { LocalStorageService, REC_WIZARD_KEY } from '@petplate/ui/lib/storage';
import qs from 'qs-lite';
import { redirect } from 'react-router-dom';
import { cookiesManager } from '../utils/cookiesManager';
import { markWizardComplete } from '../utils/recWizard';
import { isSignedIn, sdk } from '../utils/sdk';

const next = `${SUBFLOW_URL}/subscription/order-details`;

const trackPurchaseOrder = async (cartCookie: string) => {
  const cartToken = parseShopifyCartId(cartCookie);
  sdk
    .GetShopifyOrder({ cartToken: `cart_token:${cartToken}` })
    .then((shopifyOrder) => trackPurchase(shopifyOrder))
    .catch((e) => console.error(e));
};

// NOTE: To be used in a react-router loader only
export const handleCheckoutConfirmation = async ({ request }: { request: Request }) => {
  const url = new URL(request.url);
  const guestToken = url.searchParams.get('guestToken');
  const email = url.searchParams.get('email');
  const expressPlanSku = url.searchParams.get('expressPlanSku');
  const wizardId = url.searchParams.get('wizardId');
  let userExists = false;

  // This means someone came here manually without finishing the subflow
  if (!guestToken || !email || !wizardId) {
    return redirect('/subscription');
  }

  // Set the wizard as complete to avoid reusing it later
  try {
    const wizard = (LocalStorageService.get(REC_WIZARD_KEY) || {}) as Partial<RecWizard>;
    if (wizard.id === wizardId) {
      markWizardComplete();
    }

    // Track purchase event
    const cartCookie = cookiesManager.get(SHOPIFY_CART_COOKIE) || '';
    if (cartCookie) {
      trackPurchaseOrder(cartCookie);
    }
  } catch {
    // ignore and skip marking wizard complete
  }

  // if user signed in, redirect to the order confirmation page
  if (isSignedIn()) {
    return redirect(next);
  }

  // check for existence of user in our system
  if (email) {
    const existenceResp = await sdk.CheckUserExistence({ email });
    userExists = existenceResp?.userExists;
  }

  // forward params needed to associate user with order
  const redirectParams = qs.stringify({ guestToken, email, wizardId, next, expressPlanSku });

  // redirect user to sign-in if they have an account
  if (userExists) {
    return redirect(`${WEB_URL}/login?${redirectParams}`);
  }

  // otherwise redirect to sign-up
  return redirect(`${WEB_URL}/sign-up?${redirectParams}`);
};

// NOTE: To be used in a react-router loader only
export const loadOrderConfirmation = async () => {
  const cartCookie = cookiesManager.get(SHOPIFY_CART_COOKIE);

  if (!isSignedIn()) {
    return redirect(`${WEB_URL}/login`);
  }

  const wizard = (LocalStorageService.get(REC_WIZARD_KEY) || {}) as Partial<RecWizard>;
  if (!wizard.id) throw new Response('Recommendation wizard data is invalid', { status: 400 });

  if (cartCookie) {
    const cartToken = cartCookie.split('Cart/')?.pop()?.split('?key=')?.shift() || '';
    let shopifyOrder: Awaited<ReturnType<typeof sdk.GetShopifyOrder>> | undefined;
    try {
      shopifyOrder = await sdk.GetShopifyOrder({ cartToken: `cart_token:${cartToken}` });
    } catch (err) {
      // ignore and redirect later
    }

    if (!shopifyOrder) {
      throw new Response('Shopify order not found', { status: 404 });
    }

    let orderNumber: string | null | undefined;
    if (shopifyOrder.orderDetail?.id) {
      try {
        const dbOrderResp = await sdk.GetOrderIdFromShopifyId({
          id: shopifyOrder.orderDetail.id
        });
        orderNumber = dbOrderResp.dbOrder.orderNumber;
      } catch {
        // ignore and show shopify order id instead
      }
    }

    let recs:
      | Awaited<ReturnType<typeof sdk.GetConfRecommendations>>['recommendationWizard']
      | undefined;
    try {
      const resp = await sdk.GetConfRecommendations({ id: wizard.id });
      recs = resp.recommendationWizard;
    } catch {
      // ignore and redirect below
    }
    if (!recs) {
      throw new Response('Wizard recommendations not found', { status: 404 });
    }

    return {
      order: shopifyOrder,
      orderNumber,
      recs
    };
  }
};
