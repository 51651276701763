import { theme } from '@refract-ui/sc';

export const bp = {
  xxs: 0,
  xs: 320,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
};

const neutral = '#131923';
const yellow = '#FFC600';

const defaultFontStacks = {
  sans: ["'Lato'"],
  headline: ["'Agipo'"]
};

export const getTheme = (fontStacks = defaultFontStacks) =>
  theme(
    {
      // spacing values are used for things like padding and margins
      spacing: [
        0, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.75, 3.75, 5, 6.25, 7.5, 10
      ] as const,

      spacingTokens: {
        gridV: 2,
        gridH: 4
      },

      // breakpoint values
      breakpointValues: Object.values(bp),

      // set base color palette
      colors: {
        blue: '#0055B8',
        neutral,
        red: '#CC4917',
        orange: '#EC6A0F',
        pink: '#E98A75',
        purple: '#835168',
        brown: '#783D1F',
        kraft: '#C4926A',
        yellow,
        green: '#28902D',
        gold: '#CC9E00',
        goldDark: '#957301'
      },
      // shades of colors from 100 - 900\
      // these are generated automatically, but can be overridden
      colorShades: {
        blue300: '#00408A',
        blue200: '#004AA1',
        blue100: '#0055B8',
        blue90: '#0068E1',
        blue80: '#0B7BFF',
        blue70: '#3391FF',
        blue60: '#5CA7FF',
        blue50: '#85BDFF',
        blue40: '#AED3FF',
        blue30: '#D6E9FF',
        blue20: '#EBF4FF',
        neutral100: neutral,
        neutral90: '#272D37',
        neutral80: '#525F74',
        neutral70: '#8593A9',
        neutral60: '#C2C9D4',
        neutral50: '#E5E7EC',
        neutral40: '#ECEEF1',
        neutral30: '#F4F5F7',
        neutral20: '#FBFCFC',
        neutral10: '#FDFEFF',
        neutral0: '#FFFFFF',
        red300: '#993711',
        red200: '#B34014',
        red100: '#CC4917',
        red90: '#E65720',
        red80: '#E96F40',
        red70: '#ED8760',
        red60: '#F19F80',
        red50: '#F4B7A0',
        red40: '#F8CFBF',
        red30: '#FBE7DF',
        orange300: '#B1500B',
        orange200: '#CF5D0D',
        orange100: '#EC6A0F',
        orange90: '#F17C2A',
        orange80: '#F38F48',
        orange70: '#F5A167',
        orange60: '#F7B485',
        orange50: '#F9C7A4',
        orange40: '#FBDAC2',
        orange30: '#FDECE1',
        pink100: '#E98A75',
        pink90: '#EC9986',
        pink80: '#EFA798',
        pink70: '#F1B6A9',
        pink60: '#F4C5BA',
        pink50: '#F7D3CB',
        pink40: '#FAE2DD',
        pink30: '#FCF0EE',
        purple100: '#835168',
        purple90: '#9A5F7A',
        purple80: '#AA758D',
        purple70: '#B88CA0',
        purple60: '#C6A3B3',
        purple50: '#D4BAC6',
        purple40: '#E3D1D9',
        purple30: '#F1E8EC',
        brown100: '#783D1F',
        brown90: '#9C4F28',
        brown80: '#BF6131',
        brown70: '#D1794D',
        brown60: '#DA9470',
        brown50: '#E3AF94',
        brown40: '#EDCAB8',
        brown30: '#F6E4DB',
        kraft100: '#C4926A',
        kraft90: '#CBA07D',
        kraft80: '#D3AD8F',
        kraft70: '#DABBA2',
        kraft60: '#E2C9B5',
        kraft50: '#E9D6C7',
        kraft40: '#F0E4DA',
        kraft30: '#F8F1EC',
        yellow500: '#806300',
        yellow400: '#9F7C00',
        yellow300: '#BF9500',
        yellow200: '#DFAD00',
        yellow100: yellow,
        yellow90: '#FFCD20',
        yellow80: '#FFD440',
        yellow70: '#FFDB60',
        yellow60: '#FFE380',
        yellow50: '#FFEA9F',
        yellow40: '#FFF1BF',
        yellow30: '#FFF8DF',
        green400: '#195A1C',
        green300: '#1E6C22',
        green200: '#237E27',
        green100: '#28902D',
        green90: '#31B037',
        green80: '#40CA46',
        green70: '#60D365',
        green60: '#7FDC84',
        green50: '#9FE4A3',
        green40: '#BFEDC1',
        green30: '#DFF6E0'
      },

      // set theme colors. these should be pulled from the 'colors' palette
      // ideally these should correspond to something meaningful, like 'error',
      // 'info', etc
      themeColors: {
        primary: ({ colorShades }) => colorShades.blue100,
        secondary: ({ colorShades }) => colorShades.blue90
      },

      colorTokens: {
        white: ({ colorShades }) => colorShades.neutral0,
        light: ({ colorShades }) => colorShades.neutral0,
        primaryDark: ({ colorShades }) => colorShades.blue200,
        dangerFg: ({ colorShades }) => colorShades.red300,
        dangerBg: ({ colorShades }) => colorShades.red30,
        successFg: ({ colorShades }) => colorShades.green400,
        successBg: ({ colorShades }) => colorShades.green30,
        warningFg: ({ colorShades }) => colorShades.yellow500,
        warningBg: ({ colorShades }) => colorShades.yellow40,
        infoFg: ({ colorShades }) => colorShades.blue300,
        infoBg: ({ colorShades }) => colorShades.blue30,
        textDefault: ({ colors }) => colors.neutral,
        neutralFg: ({ colorShades }) => colorShades.neutral90,
        textSubtle: ({ colorShades }) => colorShades.neutral80,
        textNonessential: ({ colorShades }) => colorShades.neutral70,
        borderEmphasized: ({ colorShades }) => colorShades.neutral70,
        disabledBg: ({ colorShades }) => colorShades.neutral70,
        neutralBg: ({ colorShades }) => colorShades.neutral40,
        inputBg: ({ colorShades }) => colorShades.neutral30,
        lightBg: ({ colorShades }) => colorShades.neutral50,
        borderDefault: ({ colorShades }) => colorShades.neutral60,
        textNonessentialLight: ({ colorShades }) => colorShades.neutral60,
        borderSubtle: ({ colorShades }) => colorShades.neutral50,
        textSubtleLight: ({ colorShades }) => colorShades.neutral40,
        coolWhite: ({ colorShades }) => colorShades.neutral10,
        pureWhite: ({ colorShades }) => colorShades.neutral0,
        primaryLight: ({ colorShades }) => colorShades.neutral0,
        textDefaultLight: ({ colorShades }) => colorShades.neutral0,
        focusOutline: ({ colorShades }) => colorShades.blue60,
        hover: ({ colorShades }) => colorShades.neutral30,
        fg: neutral,
        dark: neutral,
        promo: yellow,
        lightBlueBg: ({ colorShades }) => colorShades.blue20
      },

      // shades of themeColors from 100 - 900\
      // these are generated automatically, but can be overridden
      themeColorShades: {},

      // type scale values are used to generate text sizes
      typeScale: {
        base: 1,
        scale: 1.25,
        lineHeight: '1.2'
      },

      // text sizes are a set of values from -2 through 8 that are automatically
      // derived from the above typeScale above. specific sizes can be overridden.
      // ideally we won't need to set these
      textSizes: {
        '-0.5': 0.75,
        '0.5': 0.875,
        '2.5': 1.5,
        '3': 1.5625,
        '3.5': 1.75,
        '4': 1.9375,
        '5': 2.4375,
        '6': 3.0625,
        '7': 3.8125,
        '9': 5.5
      },

      // font stacks are collections of fonts to be used in font variants.
      // these will be applied similar to this example:
      // https://gist.github.com/don1138/5761014
      fontStacks,

      // a discreet set of text variants to be used throughout the site.
      // these should build on the textSizes, allColors, and fontStacks modules
      // defined above
      //
      // these can be applied using the box mixin as follows:
      // ${({ theme }) => theme.box.t('code')}
      //
      // These variants map to the PetPlate Typography design system values:
      // https://design.petplate.com/4c865b02e/p/4520d4-typography/b/779dec
      textVariants: {
        hMega: {
          size: '9', // keyof 'textSizes' module
          stack: 'headline', // keyof 'fontStacks' module
          spacing: '-0.1625',
          height: '0.909'
        },
        h1: {
          size: '7',
          stack: 'headline',
          spacing: '-0.11438',
          height: '1.049'
        },
        h2: {
          size: '6',
          stack: 'headline',
          spacing: '-0.0938',
          height: '1.061'
        },
        h3: {
          size: '5',
          stack: 'headline',
          spacing: '-0.075',
          height: '1.128'
        },
        h4: {
          size: '4',
          stack: 'headline',
          spacing: '-0.0563',
          height: '1.161'
        },
        h5: {
          size: '3',
          stack: 'headline',
          spacing: '-0.05',
          height: '1.12'
        },
        bodyLg: {
          size: '2',
          weight: '400',
          spacing: '-0.0125',
          height: '1.4'
        },
        bodyLgBold: {
          size: '2',
          weight: '700',
          spacing: '-0.0125',
          height: '1.4'
        },
        bodyLgItalic: {
          size: '2',
          style: 'italic',
          spacing: '-0.0125',
          height: '1.4'
        },
        bodyMd: {
          size: '1',
          spacing: '-0.0125',
          height: '1.5'
        },
        bodyMdBold: {
          size: '1',
          weight: '700',
          spacing: '-0.0125',
          height: '1.5'
        },
        bodyMdItalic: {
          size: '1',
          style: 'italic',
          spacing: '-0.0125',
          height: '1.4'
        },
        bodySm: {
          size: '0.5',
          spacing: '-0.0063',
          height: '1.428'
        },
        bodySmBold: {
          size: '0.5',
          weight: '700',
          spacing: '-0.0063',
          height: '1.428'
        },
        bodySmItalic: {
          size: '0.5',
          style: 'italic',
          spacing: '-0.0063',
          height: '1.428'
        },
        bodyXSm: {
          size: '-0.75',
          spacing: '-0.0063',
          height: '1.333'
        },
        bodyXSmBold: {
          size: '-0.75',
          weight: '700',
          spacing: '-0.0063',
          height: '1.333'
        },
        bodyXSmItalic: {
          size: '-0.75',
          style: 'italic',
          spacing: '-0.0063',
          height: '1.333'
        },
        UILg: {
          size: '3.5',
          weight: '600',
          spacing: '0',
          height: '1.4'
        },
        UIMd: {
          size: '1',
          weight: '600',
          color: 'textSubtle',
          spacing: '0',
          height: '1.5'
        },
        UISm: {
          size: '0.5',
          weight: '600',
          spacing: '0',
          height: '1.428'
        },
        UIXSm: {
          size: '-0.75',
          weight: '600',
          spacing: '0.0125',
          height: '1.333'
        },
        navItemXs: {
          size: '3.5',
          color: 'primaryButtonFg'
        },
        navItemMd: {
          size: '1',
          color: 'neutral100'
        }
      },

      // a discreet collection of shadow values to be used throughout the site
      //
      // these can be applied using the box mixin as follows:
      // ${({ theme }) => theme.box.sh('elevation1')}
      shadows: {
        elevation1:
          '0.0px 4.0px 8.0px -2.0px rgba(19, 25, 35, 0.1),0.0px 1.0px 2.0px -1.0px rgba(19, 25, 35, 0.1),0.0px -1.0px 2.0px 0px rgba(19, 25, 35, 0.06),0.0px 0.0px 1.0px 0px rgba(194, 201, 212, 1.0)',
        elevation2:
          '0.0px 8.0px 16.0px -2.0px rgba(0, 0, 0, 0.1),0.0px 4.0px 8.0px -2.0px rgba(0, 0, 0, 0.1),0.0px 1.0px 2.0px -1.0px rgba(0, 0, 0, 0.1),0.0px 0.0px 1.0px 0px rgba(194, 201, 212, 1.0)',
        elevation3:
          '0.0px 16.0px 32.0px -3.0px rgba(0, 0, 0, 0.1),0.0px 8.0px 16.0px -2.0px rgba(0, 0, 0, 0.1),0.0px 4.0px 8.0px -2.0px rgba(0, 0, 0, 0.1),0.0px 1.0px 2.0px -1.0px rgba(0, 0, 0, 0.1),0.0px 0.0px 1.0px 0px rgba(194, 201, 212, 1.0)'
      },

      borders: {
        focus: {
          color: 'focusOutline',
          width: '3',
          style: 'solid'
        },
        liteFocus: {
          color: 'focusOutline',
          width: '2',
          style: 'solid'
        },
        dividerH: {
          color: 'borderSubtle',
          width: '.5',
          style: 'solid',
          radius: '28'
        },
        defaultHr: {
          color: 'borderDefault',
          width: '1',
          style: 'solid'
        },
        hidden: {
          color: 'borderSubtle',
          width: '0',
          style: 'hidden'
        },
        hiddenRadius: {
          color: 'transparent',
          width: '0',
          style: 'hidden',
          radius: '8'
        },
        dividerV: {
          color: 'borderSubtle',
          width: '1',
          style: 'dashed'
        },
        darkDivider: {
          color: 'neutral',
          width: 1,
          style: 'dashed'
        },
        ui: {
          color: 'secondary',
          width: '1',
          style: 'solid',
          radius: '4'
        },
        mediumUi: {
          color: 'secondary',
          width: '2',
          style: 'solid'
        },
        mediumBoldUi: {
          color: 'secondary',
          width: '3',
          style: 'solid',
          radius: '8'
        },
        boldUi: {
          color: 'secondary',
          width: '4',
          style: 'solid',
          radius: '8'
        },
        transparent: {
          color: 'transparent',
          width: '1',
          style: 'solid'
        },
        lightUi: {
          color: 'pureWhite',
          width: '3',
          style: 'solid'
        },
        lightUi2: {
          color: 'pureWhite',
          width: '2',
          style: 'solid'
        },
        borderDefault: {
          color: 'borderDefault',
          width: '2',
          style: 'solid',
          radius: '8'
        },
        borderLight: {
          color: 'textSubtleLight',
          width: '1',
          style: 'solid',
          radius: '16'
        },
        card: {
          color: 'borderDefault',
          width: '1',
          style: 'solid',
          radius: '8'
        },
        cardBold: {
          color: 'borderDefault',
          width: '3',
          style: 'solid',
          radius: '8'
        },
        chip: {
          color: 'borderDefault',
          width: '1',
          style: 'solid',
          radius: '28'
        }
      },

      // global HTML element styles
      // any tag specified here will have the box mixin applied with the
      // specified values
      elements: {
        h1: {
          t: 'h1'
        },
        h2: {
          t: 'h2'
        },
        h3: {
          t: 'h3'
        },
        h4: {
          t: 'h4'
        },
        h5: {
          t: 'h5'
        }
      }
    },
    {
      sm: {
        fontStacks,

        textSizes: ({ textSizes }) => ({
          ...textSizes
        }),

        elements: {
          h1: {
            t: 'h1'
          },
          h2: {
            t: 'h2'
          },
          h3: {
            t: 'h3'
          },
          h4: {
            t: 'h4'
          },
          h5: {
            t: 'h5'
          }
        },

        textVariants: {
          hMega: {
            size: '9', // keyof 'textSizes' module
            stack: 'headline', // keyof 'fontStacks' module
            spacing: '-0.1625',
            height: '0.909'
          },
          h1: {
            size: '7',
            stack: 'headline',
            spacing: '-0.11438',
            height: '1.049'
          },
          h2: {
            size: '6',
            stack: 'headline',
            spacing: '-0.0938',
            height: '1.061'
          },
          h3: {
            size: '5',
            stack: 'headline',
            spacing: '-0.075',
            height: '1.128'
          },
          h4: {
            size: '4',
            stack: 'headline',
            spacing: '-0.0563',
            height: '1.161'
          },
          h5: {
            size: '3',
            stack: 'headline',
            spacing: '-0.05',
            height: '1.12'
          },
          bodyLg: {
            size: '2',
            weight: '400',
            spacing: '-0.0125',
            height: '1.4'
          },
          bodyLgBold: {
            size: '2',
            weight: '700',
            spacing: '-0.0125',
            height: '1.4'
          },
          bodyLgItalic: {
            size: '2',
            style: 'italic',
            spacing: '-0.0125',
            height: '1.4'
          },
          bodyMd: {
            size: '1',
            spacing: '-0.0125',
            height: '1.5'
          },
          bodyMdBold: {
            size: '1',
            weight: '700',
            spacing: '-0.0125',
            height: '1.5'
          },
          bodyMdItalic: {
            size: '1',
            style: 'italic',
            spacing: '-0.0125',
            height: '1.5'
          },
          bodySm: {
            size: '0.5',
            spacing: '-0.0063',
            height: '1.428'
          },
          bodySmBold: {
            size: '0.5',
            weight: '700',
            spacing: '-0.0063',
            height: '1.428'
          },
          bodySmItalic: {
            size: '0.5',
            style: 'italic',
            spacing: '-0.0063',
            height: '1.428'
          },
          bodyXSm: {
            size: '-0.75',
            spacing: '-0.0063',
            height: '1.333'
          },
          bodyXSmBold: {
            size: '-0.75',
            weight: '700',
            spacing: '-0.0063',
            height: '1.333'
          },
          bodyXSmItalic: {
            size: '-0.75',
            style: 'italic',
            spacing: '-0.0063',
            height: '1.333'
          },
          UILg: {
            size: '3.5',
            weight: '600',
            spacing: '0',
            height: '1.4'
          },
          UIMd: {
            size: '1',
            weight: '600',
            color: 'textSubtle',
            spacing: '0',
            height: '1.5'
          },
          UISm: {
            size: '0.5',
            weight: '600',
            spacing: '0',
            height: '1.428'
          },
          UIXSm: {
            size: '-0.75',
            weight: '600',
            spacing: '0.0125',
            height: '1.333'
          }
        }
      },
      // override default settings at specified breakpoints
      md: {
        fontStacks,

        typeScale: {
          base: 1,
          scale: 1.333,
          lineHeight: '1.2'
        },
        textSizes: {
          '-2': 0.42505494897852913,
          '-1': 0.5,
          '-0.5': 0.5653230821414438,
          '0': 0.7518796992481203,
          '0.5': 0.875,
          '1': 1,
          '1.5': 1.25,
          '2': 1.33,
          '2.5': 1.5,
          '2.9': 1.75,
          '3': 1.75,
          '4': 2.375,
          '5': 3.1875,
          '6': 4.1875,
          '7': 5.625,
          '8': 7.361418135512773,
          '9': 11.25
        },
        textVariants: {
          hMega: {
            size: '9', // keyof 'textSizes' module
            stack: 'headline', // keyof 'fontStacks' module
            spacing: '-0.3375',
            height: '0.888'
          },
          h1: {
            size: '7',
            stack: 'headline',
            spacing: '-0.16875',
            height: '1.066'
          },
          h2: {
            size: '6',
            stack: 'headline',
            spacing: '-0.125',
            height: '0.940'
          },
          h3: {
            size: '5',
            stack: 'headline',
            spacing: '-0.0938',
            height: '1.098'
          },
          h4: {
            size: '4',
            height: '1.052',
            spacing: '-0.0688'
          },
          h5: {
            size: '3',
            transform: 'revert',
            spacing: '-0.05',
            height: '1.142'
          },
          bodyLg: {
            size: '1.5',
            weight: '400',
            spacing: '-0.0125',
            height: '1.4'
          },
          bodyLgBold: {
            size: '1.5',
            weight: '700',
            spacing: '-0.0125',
            height: '1.4'
          },
          bodyLgItalic: {
            size: '1.5',
            style: 'italic',
            spacing: '-0.0125',
            height: '1.4'
          },
          bodySm: {
            size: '0.5',
            spacing: '-0.0063',
            height: '1.428'
          },
          bodySmBold: {
            size: '0.5',
            weight: '700',
            spacing: '-0.0063',
            height: '1.428'
          },
          bodySmItalic: {
            size: '0.5',
            style: 'italic',
            spacing: '-0.0063',
            height: '1.428'
          },
          bodyXSm: {
            size: '0',
            spacing: '-0.0063',
            height: '1.333'
          },
          bodyXSmBold: {
            size: '0',
            weight: '700',
            spacing: '-0.0063',
            height: '1.333'
          },
          bodyXSmItalic: {
            size: '0',
            style: 'italic',
            spacing: '-0.0063',
            height: '1.333'
          },
          UILg: {
            size: '1.5',
            weight: '600',
            spacing: '0',
            height: '1.4'
          },
          UISm: {
            size: '0.5',
            weight: '600',
            spacing: '0',
            height: '1.428'
          },
          UIXSm: {
            size: '0',
            weight: '600',
            spacing: '0.0125',
            height: '1.333'
          }
        }
      }
    }
  );

export type RefractTheme = ReturnType<typeof getTheme>;
