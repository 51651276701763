import Checkmark from '@petplate/icons/core/Checkmark';
import escapeHTML from 'escape-html';
import { Fragment } from 'react';
import { Text } from 'slate';
// eslint-disable-next-line no-use-before-define
export type Children = Leaf[];

export type Leaf = {
  value?: {
    url?: string;
    alt: string;
  };
  doc?: {
    value?: {
      slug?: string;
    };
  };
  url?: string;
  children?: Children;
  [key: string]: unknown;
};

const serialize = (children: Children | undefined) =>
  children?.map((node, i) => {
    // console.log(node, i);
    if (Text.isText(node)) {
      if (node.text === '') return <br key={i} />;
      let text = <span dangerouslySetInnerHTML={{ __html: escapeHTML(node.text) }} key={i} />;

      if (node.bold) {
        text = <strong key={i}>{text}</strong>;
      }

      if (node.code) {
        text = <code key={i}>{text}</code>;
      }

      if (node.italic) {
        text = <em key={i}>{text}</em>;
      }

      if (node.keyword) {
        text = (
          <span style={{ color: '#0055B8' }} key={i}>
            {text}
          </span>
        );
      }

      if (node.sup) {
        text = (
          <sup style={{ verticalAlign: 'sub' }} key={i}>
            {text}
          </sup>
        );
      }

      if (node.underline) {
        text = (
          <span style={{ textDecoration: 'underline' }} key={i}>
            {text}
          </span>
        );
      }

      if (node.strikethrough) {
        text = (
          <span style={{ textDecoration: 'line-through' }} key={i}>
            {text}
          </span>
        );
      }

      return <Fragment key={i}>{text}</Fragment>;
    }

    if (!node) {
      return null;
    }

    switch (node.type) {
      case 'h1':
        return <h1 key={i}>{serialize(node.children)}</h1>;
      case 'h2':
        return <h2 key={i}>{serialize(node.children)}</h2>;
      case 'h3':
        return <h3 key={i}>{serialize(node.children)}</h3>;
      case 'h4':
        return <h4 key={i}>{serialize(node.children)}</h4>;
      case 'h5':
        return <h5 key={i}>{serialize(node.children)}</h5>;
      case 'h6':
        return <h6 key={i}>{serialize(node.children)}</h6>;
      case 'quote':
        return <blockquote key={i}>{serialize(node.children)}</blockquote>;
      case 'ul':
        return <ul key={i}>{serialize(node.children)}</ul>;
      case 'ol':
        return <ol key={i}>{serialize(node.children)}</ol>;
      case 'li':
        return <li key={i}>{serialize(node.children)}</li>;
      case 'checklist':
        return (
          <li key={i} style={{ listStyle: 'none' }}>
            <Checkmark color="#0068E1" /> {serialize(node.children)}
          </li>
        );
      case 'link':
        let linkUrl;
        if (node?.linkType === 'custom') {
          linkUrl = node?.url;
        } else {
          linkUrl = node?.doc?.value?.slug;
        }
        return (
          <a href={escapeHTML(linkUrl)} key={i}>
            {serialize(node.children)}
          </a>
        );

      default:
        return (
          <p style={{ marginTop: '0px', paddingTop: '0px' }} key={i}>
            {serialize(node.children)}
          </p>
        );
    }
  });

export default serialize;
