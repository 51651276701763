import { CurrentUser } from '@/types/currentUser';
import { createContext, useContext } from 'react';

export interface CurrentUserContextProps {
  currentUser?: CurrentUser;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<CurrentUser | undefined>;
  signOut: () => Promise<boolean>;
}

// CurrentUser Context with default values
export const CurrentUserContext = createContext<CurrentUserContextProps>({
  currentUser: undefined,
  loading: false,
  signIn: async (_email, _password) => {
    console.warn('Sign in not yet defined');
    return undefined;
  },
  signOut: async () => {
    console.warn('Sign out not yet defined');
    return false;
  }
});

export const useCurrentUser = () => {
  const context = useContext(CurrentUserContext);
  return context;
};
