import { CmsPage } from '@petplate/cms/src/payload-types';
import ArrowRight from '@petplate/icons/core/ArrowRight';
import lens from '@refract-ui/sc/lens';
import React, { SVGProps, useEffect, useMemo, useState } from 'react';
import styled, { css, useTheme, type DefaultTheme } from 'styled-components';
import EllipsisLoadingIndicator from '../EllipsisLoadingIndicator';
export type ButtonProps = Partial<React.ButtonHTMLAttributes<HTMLButtonElement>> & {
  size?: 'small' | 'medium' | 'large';
  label?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  color?: 'dark' | 'light';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'text';
  disabled?: boolean;
  leadingIcon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  trailingIcon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  type?: 'button' | 'submit' | 'reset';
  value?: string;
  link?: LinkTypes;
  style?: string;
  as?: string | null;
  href?: string | null;
  target?: string | null;
  rel?: string | null;
  className?: string;
  cmsIcon?: boolean;
  loading?: boolean;
  useIsSubmitted?: boolean;
  openInNewTab?: boolean;
};

const StyledButton = styled.button<{ $variant?: ButtonProps['variant'] } & ButtonProps>`
  ${({
    theme: { box, allColors, mq, textSizes },
    $variant = 'primary',
    color,
    disabled,
    size
  }) => css`
    align-items: center;
    font-weight: 700;
    border: none;
    border-radius: 0.75rem;
    cursor: pointer;
    display: flex;
    line-height: 1;
    padding: 0 1.5rem;
    min-width: 7rem;
    font-size: ${textSizes[2 as never]}rem;
    text-decoration: none;
    user-select: none;
    width: 100%;

    ${mq.sm`
      max-width: max-content;
      width: unset;
    `}

    &:focus {
      outline: none;
    }
    &:focus-visible {
      outline: 3px solid ${allColors.focusOutline};
    }

    // -------- PRIMARY STYLES
    ${$variant === 'primary' &&
    css`
      ${color === 'dark' &&
      css`
        background-color: ${allColors.secondary};
        ${box.c('white')}
        &:hover {
          background-color: ${allColors.primary};
          ${box.sh('elevation2')};
        }
      `}
      ${color === 'light' &&
      css`
        background-color: ${allColors.pureWhite};
        ${box.c('secondary')}
        &:hover {
          background-color: ${allColors.textSubtleLight};
          ${box.sh('elevation2')}
        }
      `}
    `}

    // -------- SECONDARY STYLES
    ${$variant === 'secondary' &&
    css`
      ${color === 'dark' &&
      css`
        background-color: transparent;
        ${box.b('mediumUi')}
        ${box.c('secondary')}
        &:hover, &:focus-visible {
          background-color: ${allColors.secondary};
          ${box.c('white')}

          svg {
            path {
              fill: ${allColors.white};
            }
          }
        }
      `}
      ${color === 'light' &&
      css`
        background-color: ${allColors.secondary};
        ${box.b('lightUi2')}
        ${box.c('white')}

        &:hover, &:focus-visible {
          background-color: ${allColors.pureWhite};
          ${box.c('secondary')}
        }
      `}
    `}

    // -------- TERTIARY STYLES
    ${$variant === 'tertiary' &&
    css`
      background-color: transparent;
      padding: 0;
      height: 0.25rem;
      padding: 0;
      ${color === 'dark' &&
      css`
        ${box.c('secondary')}
        &:hover {
          ${box.c('primary')}
        }
      `}
      ${color === 'light' &&
      css`
        ${box.c('white')}
        &:hover {
          ${box.c('infoBg')}
        }
      `}
    `}

    // -------- SIZE VARIANTS
    ${size === 'small' &&
    css`
      min-width: 4.875rem;
      height: 2.5rem;
      font-size: 1rem;
    `}
    ${size === 'medium' &&
    css`
      height: 3.5rem;
    `}
    ${size === 'large' &&
    css`
      height: 3.5rem;
      ${mq.md`
        height: 3.75rem;
      `}
    `}

    // --------- TEXT STYLES
    ${$variant === 'text' &&
    css`
      color: ${allColors.primary};
      height: auto;
      padding: 0;
      width: unset;
      ${mq.md`
          height: auto;
        `}
      ${color === 'dark' &&
      css`
        background-color: unset;
        &:hover {
          background-color: unset;
        }
      `}
      ${color === 'light' &&
      css`
        color: ${allColors.secondary};
        background-color: unset;
        &:hover {
          background-color: unset;
        }
      `}
    `}

    // -------- DISABLED STYLES
    ${disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    `}
  `}
`;

const Label = lens.p()``;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${Label} {
    margin: 0;
    padding: 0;
  }
`;

const Leading = styled.span`
  margin-right: 0.5rem;
  display: inline-flex;
`;

const Trailing = styled.span`
  margin-left: 0.5rem;
  display: inline-flex;
`;

const LoadingIndicator = styled(EllipsisLoadingIndicator)`
  margin-left: 0.8rem;
  margin-right: 0.5rem;
`;

type LinkTypes =
  | {
      absoluteUrl?: string;
      linkType?: string;
      page?: string | CmsPage;
      url?: string;
      stripQueryParams?: boolean;
    }
  | undefined;

const getUrl = (link: LinkTypes, windowSearchParams: string) => {
  if (link?.linkType === 'page') {
    const payloadLink = link?.page as CmsPage;
    return `/${payloadLink?.slug}`;
  } else if (link?.linkType === 'absoluteUrl') {
    const target = link?.url;
    if (!link?.stripQueryParams) {
      return `${target}${windowSearchParams}`;
    } else {
      return target;
    }
  }
  return null;
};

const Button = ({
  variant = 'primary',
  label = '',
  color = 'dark',
  size = 'large',
  onClick,
  leadingIcon,
  trailingIcon,
  disabled,
  type = 'button',
  value,
  link,
  href,
  className,
  cmsIcon,
  loading = false,
  openInNewTab = false,
  ...props
}: ButtonProps) => {
  const [windowSearchParams, setWindowSearchParams] = useState<string>('');

  // Since this button can be rendered on the server, we need to set the window search params using useEffect.
  useEffect(() => {
    setWindowSearchParams(window.location.search.toString());
  }, []);

  const linkUrl = useMemo(() => {
    return getUrl(link, windowSearchParams);
  }, [link, windowSearchParams]);

  const theme = useTheme() as DefaultTheme;
  const { allColors } = theme;

  const iconSize = (size: ButtonProps['size']) => {
    switch (size) {
      case 'small':
        return '20';
      case 'medium':
        return '24';
      case 'large':
        return '28';
    }
  };

  const iconColor = (color: ButtonProps['color']) => {
    switch (color) {
      case 'dark':
        return variant === 'secondary' ? allColors.secondary : allColors.pureWhite;
      case 'light':
        return allColors.secondary;
    }
  };

  const iconOptions = {
    height: iconSize(size),
    width: iconSize(size),
    color: iconColor(color)
  };

  const loadingIndicatorVariant = () => {
    if (
      (variant === 'primary' && color === 'light') ||
      ((variant === 'secondary' || variant === 'tertiary') && color === 'dark')
    ) {
      return 'dark';
    }

    return 'light';
  };

  return (
    <StyledButton
      as={(href || linkUrl) && 'a'}
      className={className}
      color={color}
      disabled={disabled || loading}
      href={href || linkUrl}
      onClick={onClick}
      rel={openInNewTab ? 'noreferrer' : undefined}
      size={size}
      target={openInNewTab ? '__blank' : undefined}
      type={type}
      value={value}
      $variant={variant}
      {...props}
      aria-label={props['aria-label'] || label}
    >
      <Content>
        {leadingIcon && <Leading>{React.createElement(leadingIcon, iconOptions)}</Leading>}
        <Label>{label}</Label>

        {!loading && trailingIcon && (
          <Trailing>{React.createElement(trailingIcon, iconOptions)}</Trailing>
        )}
        {(!!cmsIcon || loading) && (
          <Trailing>
            {loading ? (
              <LoadingIndicator variant={loadingIndicatorVariant()} />
            ) : (
              <ArrowRight {...iconOptions} />
            )}
          </Trailing>
        )}
      </Content>
    </StyledButton>
  );
};

export default Button;
