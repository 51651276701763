'use client';

import lens from '@refract-ui/sc/lens';
import React from 'react';
import { css } from 'styled-components';

type ContainerTypes = {
  $hasWrapper?: boolean;
  $size?: 'sm' | 'md' | 'lg';
  $variant?: 'dark' | 'light';
};

const Container = lens.div()<ContainerTypes>`
  ${({ theme: { colorShades, colorTokens }, $hasWrapper, $size, $variant }) => {
    const backgroundActive = $variant === 'light' ? colorTokens.pureWhite : colorShades.blue90;
    const backgroundInactive =
      $variant === 'light' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 104, 225, 0.2)';
    const dotSize = $size === 'sm' ? '4px' : '6px';
    const dotGap = $size === 'sm' ? '8px' : $size === 'md' ? '10px' : '12px';

    return css`
      ${$hasWrapper &&
      css`
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
      `}

      .dot-flashing {
        animation: dot-flashing 1s infinite linear alternate;
        animation-delay: 0.5s;
        background-color: ${backgroundActive};
        color: ${backgroundActive};
        height: ${dotSize};
        position: relative;
        border-radius: 100%;
        width: ${dotSize};
      }
      .dot-flashing::before,
      .dot-flashing::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
      }
      .dot-flashing::before {
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 0s;
        background-color: ${backgroundActive};
        border-radius: 100%;
        color: ${backgroundActive};
        height: ${dotSize};
        left: -${dotGap};
        width: ${dotSize};
      }
      .dot-flashing::after {
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 1s;
        background-color: ${backgroundActive};
        border-radius: 100%;
        color: ${backgroundActive};
        height: ${dotSize};
        left: ${dotGap};
        width: ${dotSize};
      }

      @keyframes dot-flashing {
        0% {
          background-color: ${backgroundActive};
        }
        50%,
        100% {
          background-color: ${backgroundInactive};
        }
      }
    `;
  }}
`;

type EllipsisLoadingIndicator = {
  className?: string;
  hasWrapper?: boolean;
  size?: 'sm' | 'md' | 'lg';
  variant?: 'dark' | 'light';
};

const EllipsisLoadingIndicator: React.FC<EllipsisLoadingIndicator> = ({
  className,
  hasWrapper = false,
  size,
  variant
}) => {
  return (
    <Container className={className} $hasWrapper={hasWrapper} $size={size} $variant={variant}>
      <div className="snippet" data-title="dot-flashing">
        <div className="stage">
          <div className="dot-flashing"></div>
        </div>
      </div>
    </Container>
  );
};

export default EllipsisLoadingIndicator;
