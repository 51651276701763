import fourOhFourDesktop from '@/assets/images/errors/404Desktop.png';
import fourOhFourMobile from '@/assets/images/errors/404Mobile.png';
import fiveHundredDesktop from '@/assets/images/errors/500Desktop.png';
import fiveHundredMobile from '@/assets/images/errors/500Mobile.png';
import ErrorDesktop from '@/assets/images/errors/ErrorDesktop.png';
import ErrorMobile from '@/assets/images/errors/ErrorMobile.png';
import PetPlateLogoMobile from '@petplate/icons/logo/PetPlateMobile';
import PetPlateLogoDesktop from '@petplate/icons/logo/PetPlateSm';
import { WEB_URL } from '@petplate/settings';
import Button from '@petplate/ui/components/Button';
import { headerHeightLg, headerHeightXs } from '@petplate/ui/constants';
import { gridSystemMargins } from '@petplate/ui/theme/mixins/block';
import lens from '@refract-ui/sc/lens';
import React from 'react';
import { css } from 'styled-components';

const Container = lens.div()`
  ${({ theme: { mq } }) => mq.lg`
    padding: 0px 1.25rem;
  `}
`;

const Header = lens.div()`
  ${({ theme: { mq, box, allColors } }) => css`
    ${gridSystemMargins()}
    background-color: ${allColors?.neutral0};
    min-height: ${headerHeightXs}px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1.125rem 1.75rem;

    ${mq.lg`
      ${box.pv(4)};
      min-height: ${headerHeightLg}px;
      padding-bottom: 0px;
      position: relative;
    `};
  `}
`;

const DesktopLogo = lens()(PetPlateLogoDesktop)`
  display: none;

  ${({ theme: { mq } }) => mq.lg`
    display: block;
  `}
`;

const MobileLogo = lens()(PetPlateLogoMobile)`
  ${({ theme: { mq } }) => mq.lg`
    display: none;
  `}
`;

const NavLogo = lens.a()``;

const Main = lens.div()`
  ${gridSystemMargins()}
  display: flex;
  flex-direction: column;
  margin-top: 2.75rem;

  ${({ theme: { mq } }) => mq.lg`
    padding-top: 3.25rem;
    padding-bottom: 7.5rem;
    display: grid;
    grid-template-columns: 2fr 3fr;
  `}
`;

const Content = lens.div()`
  min-height: calc(100% - 96px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme: { mq } }) => mq.lg`
    padding-right: 2.5rem;
  `}
`;

const Title = lens.h1({ t: 'hMega' })`
  margin: 0 0 0.25rem 0;

  ${({ theme: { mq, allColors } }) => css`
    color: ${allColors.primary};

    ${mq.lg`
      margin: 0 0 0.75rem 0;
    `}
  `}
`;

const Description = lens.p()`
  font-size: 1.25rem;
  line-height: 1.75rem;

  ${({ theme: { mq } }) => mq.lg`
    font-size: 2rem;
    line-height: 2.75rem;
  `};
`;

const ButtonContiner = lens.div()`
  display: flex;
  gap: 1rem;
  margin: 0.75rem 0px 2.5rem 0px;

  ${({ theme: { mq } }) => mq.lg`
    margin-top: 2.5rem 0px 0px 0px;
  `};
`;

const MobileImage = lens.img()`
  width: 100%;
  height: auto;

  ${({ theme: { mq } }) => mq.lg`
    display: none;
  `};
`;

const DesktopImage = lens.img()`
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: none;

  ${({ theme: { mq } }) => mq.lg`
    display: block;
  `};
`;

type ErrorPage = {
  title?: string;
  description?: string;
  errorCode?: 404 | 500 | 'other';
};

export const ErrorPage: React.FC<ErrorPage> = ({
  errorCode = 404,
  title = 'Error',
  description
}) => {
  const errorPageData = {
    404: {
      title: 'Woof!',
      description: "Sorry, it appears the page you are looking for can't be found.",
      mobileImage: fourOhFourMobile,
      desktopImage: fourOhFourDesktop,
      alt: 'Illustration of two dogs'
    },
    500: {
      title: 'Oops!',
      description:
        'Looks like something went wrong on our end. We are working on fixing the problem, sorry for the inconvenience.',
      mobileImage: fiveHundredMobile,
      desktopImage: fiveHundredDesktop,
      alt: 'Illustration of two dogs'
    },
    other: {
      title: title,
      description:
        description ||
        'Looks like something went wrong on our end. We are working on fixing the problem, sorry for the inconvenience.',
      mobileImage: ErrorMobile,
      desktopImage: ErrorDesktop,
      alt: 'Illustration of a dog jumping'
    }
  };

  return (
    <Container>
      <Header>
        <NavLogo href="/">
          <DesktopLogo />
          <MobileLogo />
        </NavLogo>
      </Header>
      <Main>
        <Content>
          <Title>{errorPageData[errorCode]?.title}</Title>
          <Description>{errorPageData[errorCode]?.description}</Description>
          <ButtonContiner>
            <Button
              label="Contact Us"
              size="medium"
              variant="secondary"
              target="_blank"
              link={{ linkType: 'absoluteUrl', url: 'https://petplatehelp.zendesk.com/hc/en-us' }}
            />
            <Button label="Homepage" size="medium" href={WEB_URL} />
          </ButtonContiner>
        </Content>
        <MobileImage
          src={errorPageData[errorCode]?.mobileImage}
          alt={errorPageData[errorCode]?.alt}
        ></MobileImage>
        <DesktopImage
          src={errorPageData[errorCode]?.desktopImage}
          alt={errorPageData[errorCode]?.alt}
        ></DesktopImage>
      </Main>
    </Container>
  );
};
