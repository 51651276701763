import Close from '@petplate/icons/core/Close';
import lens from '@refract-ui/sc/lens';
import React from 'react';
import ReactModal from 'react-modal';
import { createGlobalStyle, css } from 'styled-components';

interface Modal {
  show: boolean;
  close?: (event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void;
  className?: string;
  children: React.ReactNode;
  large?: boolean;
  full?: boolean;
}

const ModalGlobalStyle = createGlobalStyle`
  .ReactModal__Body--open {
    overflow: hidden;
  }

  .ReactModal__Overlay {
    align-items: center;
    justify-content: flex-end;
    opacity: 0;
    transition: opacity .3s ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;

    ${({ theme }) => theme.mq.lg`
      justify-content: center;
    `}
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .ReactModal__Content--before-close {
    height: 0;
  }

  .ReactModal__Content--after-open {
    height: auto;
  }

  .ReactModal__Content {
    border-radius: 0.75rem 0.75rem 0 0;
    margin: 3.75rem auto 0;
    overflow: auto;
    padding: 0;
    position: relative;
    width: 100%;
    outline: none;
    transition: height .3s ease-in-out;

    ${({ theme }) => theme.mq.lg`
      margin: 0 auto;
      width: auto;
    `}
  }
`;

const settings = {
  style: {
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column' as const,
      zIndex: 1000
    }
  }
};

const Content = lens.div()<{ $large: Modal['large']; $full: Modal['full'] }>`
  ${({ theme: { box, allColors, mq, fontStacks }, $large, $full }) => css`
    background: ${allColors.pureWhite};
    border-radius: 0.75rem 0.75rem 0 0;
    margin: 0 auto;
    min-width: 100vw;
    padding: 2.5rem 1.5rem;
    ${$full &&
    css`
      padding: 0 !important;
    `}
    position: relative;
    gap: 1.5rem;
    z-index: 1001;
    ${box.t('bodyMd')}
    font-family: ${fontStacks?.sans?.join(' ')};

    h1:nth-child(1) {
      ${box.t('h3')};
      margin-top: 0;
    }

    p {
      ${box.t('bodyMd')}
    }

    ${mq.md`
      min-width: 100vw;
    `}

    ${mq.lg`
      border-radius: 0.75rem;
      padding: 1.75rem;
      min-width: 23.4375rem;
      max-width: 23.4375rem;

      ${
        $large &&
        css`
          min-width: 30rem;
          max-width: 51rem;
        `
      }
    `}
  `}
`;

const CloseButton = lens.button()`
  background: 0;
  border: 0;
  outline: 0;
  padding: 0;
  position: absolute;
  right: 0.675rem;
  top: 0.675rem;
  text-align: right;
  z-index: 100;
`;

const Modal: React.FC<Modal> = ({ show, close, className, children, large, full }) => {
  if (!show) {
    return null;
  }

  return (
    <>
      <ModalGlobalStyle />
      <ReactModal
        className={`petplate-modal ${className}`}
        isOpen={show}
        onRequestClose={close}
        ariaHideApp={false}
        {...settings}
      >
        <Content $large={large} $full={full}>
          <CloseButton onClick={close}>
            <Close />
          </CloseButton>
          <>{children}</>
        </Content>
      </ReactModal>
    </>
  );
};

export default Modal;
