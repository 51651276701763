import { ErrorPage } from '@/components/layouts/ErrorPage';
import { NOT_FOUND } from '@/lib/statusCodes';
import { useRouteError } from 'react-router-dom';

const isResponse = (error: unknown): error is Response => !!(error as Response).status;

export const ErrorBoundary = () => {
  const error = useRouteError();

  if (isResponse(error)) {
    if (error.status === NOT_FOUND) return <ErrorPage errorCode={404} />;
  }
  return <ErrorPage errorCode={500} />;
};
