import { useEffect } from 'react';

const BASE_PAGE_TITLE = 'PetPlate | New Subscription';

export const usePageTitle = (title: string) => {
  useEffect(() => {
    document.title = `${BASE_PAGE_TITLE} | ${title}`;
    return () => {
      document.title = BASE_PAGE_TITLE;
    };
  }, []);
};
