import { type RecWizard } from '@/types/recWizard';
import isEmpty from 'lodash/isEmpty';
import { createContext, useContext } from 'react';

export interface RecWizardContextProps {
  wizard?: Partial<RecWizard>;
  updateWizard: (wizard: Partial<RecWizard>) => void;
  loading: boolean;
}

// RecWizard context with default values
export const RecWizardContext = createContext<RecWizardContextProps>({
  wizard: {},
  updateWizard: (_wizard) => {
    console.warn('Update wizard not yet defined.');
  },
  loading: false
});

export const useRecWizard = (): RecWizardContextProps => {
  const context = useContext(RecWizardContext);
  if (isEmpty(context)) throw new Error('Called rec wizard before setting context');
  return context;
};
