import { WizardProgressBar } from '@/components/WizardProgressBar';
import { ProductRecsProvider } from '@/components/providers/ProductRecsProvider';
import { StepGroups, SubflowSteps } from '@/types/steps';
import {
  REVIEW_TYPE_COMPANY,
  REVIEW_TYPE_SUPPLEMENTS,
  REVIEW_TYPE_TREATS,
  ReviewCategoryType
} from '@petplate/utils/yotpo';
import some from 'lodash/some';
import React, { useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import {
  BackButton,
  Container,
  HeadContainer,
  HeadWrapper,
  MainIntro,
  MainPet,
  MainProducts,
  PromoBanner,
  StyledLogo,
  Top,
  YotpoReviewsCarousel
} from '../components';

const stepsToShowYotpoReviews = [
  SubflowSteps.Plan,
  SubflowSteps.Plans,
  SubflowSteps.Recipes,
  SubflowSteps.Treats,
  SubflowSteps.Supplements
];

type StepLayoutProps = {
  view: StepGroups;
};

export const StepLayout: React.FC<StepLayoutProps> = ({ view }) => {
  const { pathname } = useLocation();

  const showYotpoReviews = some(stepsToShowYotpoReviews, (step) => pathname.includes(step));

  const reviewType: ReviewCategoryType = useMemo(() => {
    if (pathname.includes(SubflowSteps.Treats)) {
      return REVIEW_TYPE_TREATS;
    } else if (pathname.includes(SubflowSteps.Supplements)) {
      return REVIEW_TYPE_SUPPLEMENTS;
    } else {
      return REVIEW_TYPE_COMPANY;
    }
  }, [pathname]);

  return (
    <>
      <Container $isPageWithReviews={showYotpoReviews}>
        <PromoBanner />
        <HeadWrapper>
          <HeadContainer>
            <Top>
              {!pathname.includes(SubflowSteps.Intro) && <BackButton />}
              <StyledLogo />
            </Top>
            <WizardProgressBar />
          </HeadContainer>
        </HeadWrapper>
        {view === StepGroups.Intro && (
          <MainIntro>
            <Outlet />
          </MainIntro>
        )}
        {view === StepGroups.Pet && (
          <MainPet>
            <Outlet />
          </MainPet>
        )}
        {view === StepGroups.Products && (
          <MainProducts>
            <ProductRecsProvider>
              <Outlet />
            </ProductRecsProvider>
          </MainProducts>
        )}
      </Container>
      {showYotpoReviews && (
        <YotpoReviewsCarousel
          blockType="YotpoReviewsCarousel"
          backgroundColor="blue"
          textAlignment="center"
          reviewType={reviewType}
        />
      )}
    </>
  );
};
