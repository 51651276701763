import { Flows, type Step } from '@/types/steps';
import { createContext, useContext } from 'react';

export interface SubflowContextProps {
  steps: Step[];
  flow: Flows;
  currentStepIdx: number;
}

// RecWizard context with default values
export const SubflowContext = createContext<SubflowContextProps>({
  steps: [],
  flow: Flows.Regular,
  currentStepIdx: 0
});

export const useCurrentFlow = (): SubflowContextProps => {
  const context = useContext(SubflowContext);
  return context;
};
