import CircleCheckmark from '@petplate/icons/core/CircleCheckmark';
import lens from '@refract-ui/sc/lens';
import React from 'react';
import { css } from 'styled-components';
import Stars from './Stars';

const Container = lens.div()`
  ${({ theme: { box, mq } }) => css`
    ${box.b('card')}
    ${box.sh('elevation1')}
    display: flex;
    flex-direction: column;
    min-height: 15rem;
    padding: 1.5rem;
    width: 100%;

    ${mq.md`
      height: auto;
    `}
  `}
`;

const Top = lens.div()`
  padding-bottom: 1.25rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ReadMore = lens.div()`
  padding-top: 1.25rem;
  width: 100%;
  margin-top: auto;
  color: #0068E1;
  text-decoration: underline #0068E1;
  -webkit-text-decoration-line: underline;
  -webkit-text-decoration-color: #0068E1;
`;

const Rating = lens.div()`
  position: relative;
`;

const CustName = lens.span()`
  ${({ theme: { box } }) => css`
    padding-bottom: 0.5rem;
    ${box.t('bodyLgBold')}

    svg {
      margin-bottom: 0.25rem;
    }
  `}
`;

const Review = lens.span()<{ $clamp: ReviewCard['clamp'] }>`
  ${({ theme: { box }, $clamp }) => css`
    ${box.t('bodyLg')}
    display: -webkit-box;
    -webkit-line-clamp: ${$clamp};
    -webkit-box-orient: vertical;
    overflow: hidden;
  `}
`;

type ReviewCard = {
  rating?: number;
  customerName?: string;
  review?: string;
  className?: string;
  clamp?: number | 'none';
  showReadMore?: boolean;
  onClick?: (event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void;
};

const ReviewCard: React.FC<ReviewCard> = ({
  rating = 5,
  customerName,
  review,
  className,
  clamp = 4,
  showReadMore = true,
  onClick
}) => {
  return (
    <Container className={className} role="button" onClick={onClick}>
      <Top>
        <Rating>
          <Stars score={rating} />
        </Rating>
      </Top>
      <CustName>
        {customerName} <CircleCheckmark fill="#31B037" stroke="#31B037" color="#FFFFFF" />{' '}
      </CustName>
      <Review $clamp={clamp}>{review}</Review>
      {showReadMore && <ReadMore>Read more...</ReadMore>}
    </Container>
  );
};

export default ReviewCard;
