import EllipsisLoadingIndicator from '@petplate/ui/components/EllipsisLoadingIndicator';
import { maxContentWidth } from '@petplate/ui/constants';
import lens from '@refract-ui/sc/lens';

const Container = lens.div()`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: ${maxContentWidth};
  padding-top: 9rem;
  min-height: 100svh;
`;

export const ResumeSubFlow = () => {
  return (
    <Container>
      <h2>Signing you in...</h2>
      <EllipsisLoadingIndicator />
    </Container>
  );
};
