import SvgArrowLeft from '@petplate/icons/core/ArrowLeft';
import SvgArrowRight from '@petplate/icons/core/ArrowRight';
import lens from '@refract-ui/sc/lens';
import React from 'react';
import { css } from 'styled-components';

const Arrow = lens.button()`
  ${({ theme }) => css`
    align-items: center;
    background: none;
    border: 0;
    display: flex;
    margin: 0;
    padding: 0;
    transition: background 0.15s ease-in-out;

    svg {
      height: 28px;
      width: 28px;

      path {
        fill: ${theme?.themeColors?.primary};
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.8;
      svg {
        path {
          fill: ${theme?.colorTokens?.textNonessential};
        }
      }
    }

    &:hover {
      background: ${theme?.colorTokens?.hover};
    }

    ${theme.mq.xl`
      svg {
        height: 36px;
        width: 36px;
      }
    `}
  `}
`;

type ArrowsContainerProps = {
  $maxWidth?: '768px' | '1100px' | '1400px' | 'none';
};

const ArrowsContainer = lens.div()<ArrowsContainerProps>`
  ${({ $maxWidth }) => css`
    display: flex;
    gap: 1.4531rem;
    max-width: ${$maxWidth};
    width: 100%;
  `}
`;

const NavContainer = lens.div()`
  display: flex;
`;

const PaginationContainer = lens.ul()`
  align-items: center;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
`;

type DotTypes = {
  $isActive?: boolean;
};

const PaginationDot = lens.li()<DotTypes>`
  ${({ $isActive, theme }) => css`
    background-color: ${$isActive ? theme.themeColors.primary : theme.colorShades.neutral50};
    border-radius: 50%;
    flex: 0 0 0.8125rem;
    height: 0.8125rem;
    list-style-type: none;
    transition: background 0.15s ease-in-out;
    width: 0.8125rem;

    &:not(:last-child) {
      margin-right: 0.75rem;
    }
  `}
`;

type CarouselNavigation = {
  activeDotIndex?: number;
  carouselLength?: number;
  isNextButtonEnabled?: boolean;
  isPreviousButtonEnabled?: boolean;
  showDots?: boolean;
  slideNext?: () => void;
  slidePrevious?: () => void;
};

const CarouselNavigation: React.FC<CarouselNavigation> = ({
  activeDotIndex = 0,
  carouselLength = 0,
  isNextButtonEnabled = true,
  isPreviousButtonEnabled = false,
  showDots = false,
  slideNext,
  slidePrevious
}) => {
  return (
    <NavContainer>
      <ArrowsContainer>
        <Arrow onClick={slidePrevious} disabled={!isPreviousButtonEnabled} tabIndex={0}>
          <SvgArrowLeft viewBox="0 0 20 20" />
        </Arrow>
        <Arrow onClick={slideNext} disabled={!isNextButtonEnabled} tabIndex={0}>
          <SvgArrowRight viewBox="0 0 20 20" />
        </Arrow>
      </ArrowsContainer>
      {showDots && (
        <PaginationContainer>
          {[...Array(carouselLength)].map((_, index) => (
            <PaginationDot key={index} $isActive={activeDotIndex === index} />
          ))}
        </PaginationContainer>
      )}
    </NavContainer>
  );
};

export default CarouselNavigation;
