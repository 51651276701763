import { syncRecWizard } from '@/lib/utils/recWizard';
import { type RecWizard } from '@/types/recWizard';
import { type Recommendations } from '@/types/recommendations';
import { createContext, useContext } from 'react';

export const fetchWizardRecommendations = async (wizard: Partial<RecWizard>) => {
  try {
    if (wizard.id) {
      // Updating wizard with the current values from the cookie will return new recommendations
      const { recommendationWizardUpdate } = await syncRecWizard(wizard);
      const wizardData = recommendationWizardUpdate?.recommendationWizard;
      if (wizardData) {
        return {
          planRecommendations: wizardData?.planRecommendations,
          recommendedRecipes: wizardData?.recommendedRecipes,
          recommendedComboRecipes: wizardData?.recommendedComboRecipes,
          recommendedAddons: wizardData?.recommendedAddons
        } as Recommendations;
      }
    }
    return {} as Recommendations;
  } catch (err) {
    console.log(err);
    throw new Response('Failed to load recommendations', { status: 500 });
  }
};

export interface ProductRecsContextProps {
  recommendations?: Recommendations;
  loading: boolean;
  refreshRecommendations: () => void;
}

export const ProductRecsContext = createContext<ProductRecsContextProps>({
  recommendations: undefined,
  loading: false,
  refreshRecommendations: () => {}
});

export const useProductRecs = () => {
  const context = useContext(ProductRecsContext);
  return context;
};
