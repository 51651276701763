import findIndex from 'lodash/findIndex';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCurrentFlow } from './useCurrentFlow';

export const useAdvanceStep = () => {
  const { pathname, search } = useLocation();
  const { steps } = useCurrentFlow();
  const navigate = useNavigate();

  return () => {
    const currentStepIdx = findIndex(steps, ({ fullPath }) => fullPath.includes(pathname));
    const nextStep = steps[currentStepIdx + 1];
    if (nextStep) {
      navigate({ pathname: nextStep.fullPath, search });
    }
  };
};

export const useGoBackStep = () => {
  const { pathname, search } = useLocation();
  const { steps } = useCurrentFlow();
  const navigate = useNavigate();

  return () => {
    const currentStepIdx = findIndex(steps, ({ fullPath }) => fullPath.includes(pathname));
    const prevStep = steps[currentStepIdx - 1];
    if (prevStep) {
      navigate({ pathname: prevStep.fullPath, search });
    }
  };
};
