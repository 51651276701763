import { SVGProps } from 'react';
const SvgArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '20'}
    height={props.height || '20'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={props.viewBox || '0 0 20 20'}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99 5.287a.75.75 0 0 0-1.047-1.075L1.557 9.457a.805.805 0 0 0-.013.012L.998 10l.552.537 5.393 5.25a.75.75 0 0 0 1.046-1.074L3.92 10.75h14.333a.75.75 0 1 0 0-1.5H3.919l4.07-3.963Z"
      fill={props.color || '#131923'}
      stroke={props.color || '#131923'}
      strokeWidth={0.1}
      vectorEffect={`non-scaling-stroke`}
    />
  </svg>
);
export default SvgArrowLeft;
