import { ProductRecsContext, fetchWizardRecommendations } from '@/lib/hooks/useProductRecs';
import { useRecWizard } from '@/lib/hooks/useRecWizard';
import { type RecWizard } from '@/types/recWizard';
import { type Recommendations } from '@/types/recommendations';
import { useAsyncError } from '@petplate/ui/hooks/useAsyncError';
import React, { useEffect, useState } from 'react';

export const ProductRecsProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { wizard } = useRecWizard();
  const [recommendations, setRecommendations] = useState<Recommendations>();
  const [loading, setLoading] = useState(true);
  const [needsRefresh, setNeedsRefresh] = useState(true);
  const throwAsyncError = useAsyncError();

  const loadRecommendations = (wizard: Partial<RecWizard>) => {
    setLoading(true);
    setRecommendations(undefined);
    fetchWizardRecommendations(wizard)
      .then((recs) => {
        setRecommendations(recs);
        setLoading(false);
        setNeedsRefresh(false);
      })
      .catch(throwAsyncError);
  };

  const refreshRecommendations = () => {
    setNeedsRefresh(true);
  };

  useEffect(() => {
    if (needsRefresh && wizard?.id) {
      loadRecommendations(wizard);
    }
  }, [needsRefresh, wizard]);

  return (
    <ProductRecsContext.Provider
      value={{
        recommendations,
        loading: loading || needsRefresh,
        refreshRecommendations
      }}
    >
      {children}
    </ProductRecsContext.Provider>
  );
};
