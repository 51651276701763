import React from 'react';
const SvgCheckmark = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '20'}
    height={props.height || '20'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={props.viewBox || '0 0 20 20'}
    {...props}
  >
    <path
      d="M3 10.833 7.118 15 17 5"
      stroke={props.color || '#131923'}
      strokeWidth={1.5}
      strokeLinecap="round"
      vectorEffect={`non-scaling-stroke`}
    />
  </svg>
);
export default SvgCheckmark;
