import { SubflowSteps } from '@/types/steps';

export type ProgressBarStep = {
  title: string;
  substeps: (typeof SubflowSteps)[keyof typeof SubflowSteps][];
};

/**
 * NOTE: Kind of duplicate code that doesn't respect multiple flow, but works for now
 */
export const PROGRESS_BAR_STEPS: ProgressBarStep[] = [
  {
    title: 'Parent',
    substeps: [SubflowSteps.Intro, SubflowSteps.Name, SubflowSteps.ParentEmail]
  },
  {
    title: 'Pet',
    substeps: [
      SubflowSteps.Gender,
      SubflowSteps.Breed,
      SubflowSteps.Age,
      SubflowSteps.Weight,
      SubflowSteps.Activity,
      SubflowSteps.Waistline,
      SubflowSteps.Sensitivities,
      SubflowSteps.Goals
    ]
  },
  {
    title: 'Plan',
    substeps: [SubflowSteps.Plan, SubflowSteps.Plans, SubflowSteps.Recipes]
  },
  {
    title: 'Checkout',
    substeps: [
      SubflowSteps.Treats,
      SubflowSteps.Supplements,
      SubflowSteps.Email,
      SubflowSteps.PlanConfirmation
    ]
  }
];
