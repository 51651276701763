import { SVGProps } from 'react';
const SvgArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '20'}
    height={props.height || '20'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={props.viewBox || '0 0 20 20'}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.01 14.713a.75.75 0 1 0 1.047 1.075l5.386-5.245a.676.676 0 0 0 .013-.011l.546-.532-.552-.537-5.393-5.25a.75.75 0 1 0-1.046 1.074l4.07 3.963H1.748a.75.75 0 0 0 0 1.5h14.333l-4.07 3.963Z"
      fill={props.color || '#131923'}
      stroke={props.color || '#131923'}
      strokeWidth={0.1}
      vectorEffect={`non-scaling-stroke`}
    />
  </svg>
);
export default SvgArrowRight;
