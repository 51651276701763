'use client';

import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useRef, useState } from 'react';

export default function useCarousel<T extends HTMLDivElement>({ scrollAmount = 0 } = {}) {
  const carouselScrollerRef = useRef<T>(null);
  const [isPreviousButtonEnabled, setIsPreviousButtonEnabled] = useState(false);
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(true);
  const [activeDotIndex, setActiveDotIndex] = useState(0);

  const slideNext = useMemo(
    () =>
      debounce(() => {
        carouselScrollerRef.current?.scrollBy({ left: scrollAmount });
      }, 300),
    [scrollAmount]
  );

  const slidePrevious = useMemo(
    () =>
      debounce(() => {
        carouselScrollerRef.current?.scrollBy({ left: -scrollAmount });
      }, 300),
    [scrollAmount]
  );

  const handleScroll = useMemo(
    () =>
      debounce((event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const { scrollLeft, offsetWidth, scrollWidth } = event.target as T;

        const index = Math.round(scrollLeft / offsetWidth);
        const isAtStart = scrollLeft === 0;
        const isAtEnd = offsetWidth + scrollLeft + 1 >= scrollWidth;

        setActiveDotIndex(index);
        setIsPreviousButtonEnabled(!isAtStart);
        setIsNextButtonEnabled(!isAtEnd);
      }, 100),
    []
  );

  // Stop the invocation of the debounced functions
  // after unmounting
  useEffect(() => {
    return () => {
      slideNext.cancel();
      slidePrevious.cancel();
      handleScroll.cancel();
    };
  }, [handleScroll, slideNext, slidePrevious]);

  return {
    carouselScrollerRef,
    slideNext,
    slidePrevious,
    isPreviousButtonEnabled,
    isNextButtonEnabled,
    activeDotIndex,
    handleScroll
  };
}
