import { SVGProps } from 'react';
const SvgStarFull = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '20'}
    height={props.height || '20'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={props.viewBox || '0 0 20 20'}
    {...props}
  >
    <g clipPath="url(#star-full_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.357 16.483a1.2 1.2 0 0 1 1.287 0l3.095 1.966c.902.573 2.046-.23 1.815-1.272l-.864-3.899a1.2 1.2 0 0 1 .363-1.146l2.93-2.672c.772-.704.339-1.989-.702-2.082l-3.74-.334a1.2 1.2 0 0 1-1.005-.747l-1.423-3.532c-.403-1.003-1.823-1.003-2.226 0L7.464 6.297a1.2 1.2 0 0 1-1.006.747l-3.74.334c-1.04.093-1.473 1.378-.701 2.082l2.93 2.672a1.2 1.2 0 0 1 .363 1.146l-.864 3.899c-.231 1.043.913 1.845 1.815 1.272l3.096-1.966Z"
        fill={props.color || '#131923'}
        stroke={props.color || '#131923'}
        strokeWidth={0.1}
        vectorEffect={`non-scaling-stroke`}
      />
    </g>
    <defs>
      <clipPath id="star-full_svg__a">
        <path
          fill="#fff"
          d="M0 0h20v20H0z"
          stroke={props.color || '#131923'}
          strokeWidth={0.1}
          vectorEffect={`non-scaling-stroke`}
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgStarFull;
