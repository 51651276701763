import { SubflowContext } from '@/lib/hooks/useCurrentFlow';
import { PROGRESS_BAR_STEPS, ProgressBarStep } from '@/lib/wizardProgressBarUtils';
import { Flows, type Step } from '@/types/steps';
import { useLocation } from 'react-router-dom';

type SubflowProviderProps = {
  flow: Flows;
  steps: Step[];
  children: React.ReactNode;
};

export const SubflowProvider = ({ flow, steps, children }: SubflowProviderProps) => {
  const { pathname } = useLocation();
  const currentStep = `/${pathname.split('/').pop()}` as Step['path'];
  const findStepCb = (step: ProgressBarStep) => step.substeps.includes(currentStep);
  const currentStepIdx = PROGRESS_BAR_STEPS.findIndex(findStepCb);

  return (
    <SubflowContext.Provider value={{ flow, steps, currentStepIdx }}>
      {children}
    </SubflowContext.Provider>
  );
};
