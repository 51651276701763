import isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { isSignedIn } from '../utils/sdk';
import { useCurrentUser } from './useCurrentUser';

export const useShowPromo = () => {
  const { currentUser, loading } = useCurrentUser();
  const [show, setShow] = useState(!loading && isNil(currentUser));

  useEffect(() => {
    if (loading) {
      setShow(false);
    } else if (isSignedIn() && currentUser) {
      setShow(!currentUser.hasSubscriptions);
    } else {
      setShow(true);
    }
  }, [currentUser, loading]);

  return show;
};
