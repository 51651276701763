import { RecWizard } from '@/types/recWizard';
import { SubflowSteps, type Step } from '@/types/steps';
import findIndex from 'lodash/findIndex';
import first from 'lodash/first';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useCurrentFlow } from './useCurrentFlow';

export const useLatestStep = (wizard?: Partial<RecWizard>) => {
  const { pathname } = useLocation();
  const { steps } = useCurrentFlow();

  return useMemo(() => {
    const currentStepIdx = findIndex(steps, { fullPath: pathname as Step['fullPath'] });
    const latestStepIdx = findIndex(steps, ({ fullPath }) =>
      fullPath.includes(wizard?.latestStep || SubflowSteps.Intro)
    );

    return currentStepIdx > latestStepIdx
      ? (pathname as Step['fullPath'])
      : wizard?.latestStep || first(steps)?.fullPath;
  }, [steps, pathname, wizard?.latestStep]);
};
