import { useCurrentFlow } from '@/lib/hooks/useCurrentFlow';
import { PROGRESS_BAR_STEPS } from '@/lib/wizardProgressBarUtils';
import Checkmark from '@petplate/icons/core/Checkmark';
import lens from '@refract-ui/sc/lens';
import { map } from 'lodash';
import { css } from 'styled-components';

const Container = lens.div()`
  ${({ theme: { mq } }) => css`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    ${mq.sm`
      margin: 0 auto;
      width: 480px;
    `}
  `}
`;

const BarContainer = lens.div()`
  ${({ theme: { box } }) => css`
    border-radius: 0.25rem;
    width: 100%;
    height: 0.5rem;
    overflow: hidden;
    ${box.bg('neutralBg')}
  `}
`;

const BarProgress = lens.div()<{ $baseProgress?: number; $progress: number }>`
  ${({ theme: { box }, $progress, $baseProgress }) => css`
    width: ${$progress || $baseProgress}%;
    height: 100%;
    border-radius: 0.25rem;
    transition: width 0.1s ease-in-out;
    ${box.bg('secondary')}
  `}
`;

const StepLabels = lens.div()`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const Label = lens.p()<{ $current: boolean }>`
  ${({ theme: { box, mq }, $current }) => css`
    ${box.t('UISm')}
    ${box.c('secondary')}
    margin: auto;
    padding-top: 0 !important;
    margin: 0 !important;
    align-items: center;
    height: 20px;
    flex: 33%;
    gap: 0.1875rem;
    display: ${$current ? 'flex' : 'none'};
    justify-content: center;

    ${mq.sm`
      display: flex;

      // Adjustments to match the designs
      &:first-child {
        justify-content: flex-start;
        padding-left: 25px;
      }

      &:nth-child(2) {
        margin-left: -1px;
      }

      &:last-child {
        justify-content: flex-end;
        padding-right: 20px;
      }
    `}
  `}
`;

export const WizardProgressBar = () => {
  const { currentStepIdx } = useCurrentFlow();

  return (
    <Container>
      <BarContainer>
        <BarProgress
          $progress={(currentStepIdx / (PROGRESS_BAR_STEPS.length || 1)) * 100}
          $baseProgress={9}
        />
      </BarContainer>
      <StepLabels>
        {map(PROGRESS_BAR_STEPS, (step, stepIdx) => {
          const isCompleted = currentStepIdx > stepIdx;
          const isCurrent = currentStepIdx === stepIdx;
          return (
            <Label key={step.title} $current={isCurrent}>
              {isCompleted ? <Checkmark width={16} height={16} color="#0068E1" /> : step.title}
            </Label>
          );
        })}
      </StepLabels>
    </Container>
  );
};
