// import { Analytics } from '@vercel/analytics/react';
import { ENV, NEXT_PUBLIC_SENTRY_DSN } from '@petplate/settings';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { SubflowApp } from './routes';
import './styles/global.css';

// Initialize Sentry
Sentry.init({
  dsn: NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: ENV,
  enableTracing: true,
  attachStacktrace: true
});

ReactDOM.createRoot(document.getElementById('subflow-root')!).render(
  <React.StrictMode>
    {/* <Analytics /> */}
    <SubflowApp />
  </React.StrictMode>
);
