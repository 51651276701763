import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { sdk } from '../utils/sdk';

/**
 * Hook to handle ReCAPTCHA verification
 * Since it uses v3, there is no interaction with the user.
 * It will simply execute a test and return a token if the user passes it.
 */
export const useCaptcha = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const verifyCaptcha = useCallback(
    async (key: string) => {
      if (!executeRecaptcha) return false;
      const token = await executeRecaptcha(key);
      // After receiving the encrypted token response, we need to ask google if the score is positive
      const { verifyCaptchaToken } = await sdk.VerifyCaptchaResult({ token });
      return isEmpty(verifyCaptchaToken?.errors) && !!verifyCaptchaToken?.success;
    },
    [executeRecaptcha]
  );

  return {
    verifyCaptcha,
    captchaReady: typeof executeRecaptcha === 'function'
  };
};
