import lens from '@refract-ui/sc/lens';
import React from 'react';
import { css } from 'styled-components';
import serialize, { Children } from './serialize';

const RichTextContainer = lens.div()`
  ${({ theme: { box, themeColors } }) => css`
    * {
      margin: 0;
    }

    p {
      ${box.t('bodyMd')}
    }

    a {
      color: ${themeColors?.secondary};
      text-decoration: none;
    }
  `}
`;

const RichText: React.FC<{
  className?: string;
  content?: Children;
}> = ({ className, content }) => {
  if (!content) {
    return null;
  }

  // don't render if rich text is empty
  if (
    content.length === 1 &&
    content?.[0].children?.length === 1 &&
    !content?.[0].children[0].text
  ) {
    return null;
  }

  return <RichTextContainer className={className}>{serialize(content)}</RichTextContainer>;
};

export default RichText;
