'use client';

import { Coupon } from '@petplate/schema';
import { type Coupon as CouponTypeExtra } from '@petplate/utils/coupons';
import isEmpty from 'lodash/isEmpty';
import React, { createContext, useContext } from 'react';

type DiscountProviderProps = {
  discountPercentCoupon?: Partial<Coupon & CouponTypeExtra>;
  discountAmountCoupon?: Partial<Coupon & CouponTypeExtra>;
  siteWideBackupCode?: Partial<Coupon & CouponTypeExtra>;
  subCancelledEntreesDiscount?: Partial<Coupon>;
  loading: boolean;
};

const defaultContext = {
  discountPercentCoupon: undefined,
  discountAmountCoupon: undefined,
  siteWideBackupCode: undefined,
  subCancelledEntreesDiscount: undefined,
  loading: false
};

const DiscountContext = createContext<DiscountProviderProps>(defaultContext);

export const DiscountProvider = ({
  discountAmountCoupon,
  discountPercentCoupon,
  siteWideBackupCode,
  subCancelledEntreesDiscount,
  loading,
  children
}: DiscountProviderProps & {
  children: React.ReactNode;
}) => {
  return (
    <DiscountContext.Provider
      value={{
        discountPercentCoupon,
        discountAmountCoupon,
        siteWideBackupCode,
        subCancelledEntreesDiscount,
        loading
      }}
    >
      {children}
    </DiscountContext.Provider>
  );
};

export const useSiteWideDiscount = (): DiscountProviderProps => {
  const context = useContext(DiscountContext);

  if (isEmpty(context)) {
    throw new Error('Called useSiteWideDiscount before setting DiscountProvider context');
  }
  return context;
};

export default DiscountProvider;
