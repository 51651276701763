import { SubflowProvider } from '@/components/providers/SubflowProvider';
import { Flows, type Step } from '@/types/steps';
import { Outlet } from 'react-router-dom';
import { RecWizardProvider } from '../../providers/RecWizardProvider';

export const SubscriptionFlow = ({ flow, steps }: { flow: Flows; steps: Step[] }) => {
  return (
    <SubflowProvider flow={flow} steps={steps}>
      <RecWizardProvider>
        <Outlet />
      </RecWizardProvider>
    </SubflowProvider>
  );
};
