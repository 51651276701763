import { getTheme } from '@petplate/ui/theme';
import ScrollToOffset from '@petplate/ui/theme/utils/scrollToOffset';
import GlobalStyles from '@refract-ui/sc/components/GlobalStyles';
import React, { createContext, useContext, useState } from 'react';

interface T {
  children?: React.ReactNode;
  fontStacks?: {
    sans: Array<string>;
    headline: Array<string>;
  };
}

const containerStyles = {
  display: 'grid',
  minHeight: '100svh',
  gridTemplateRows: '1fr'
};

interface ThemeContextProps {
  theme?: ReturnType<typeof getTheme>;
}

const ThemeContext = createContext<ThemeContextProps>({
  theme: undefined
});

const ThemeProvider: React.FC<T> = ({ children, fontStacks }) => {
  const [theme] = useState(getTheme(fontStacks));

  return (
    <ThemeContext.Provider value={{ theme }}>
      <GlobalStyles theme={theme} style={containerStyles}>
        <ScrollToOffset />
        {children}
      </GlobalStyles>
    </ThemeContext.Provider>
  );
};

export const useCurrentTheme = () => {
  const { theme } = useContext(ThemeContext);
  return theme;
};

export default ThemeProvider;
